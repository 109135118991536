import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import clsx from 'clsx';
import { useField, useFormikContext } from 'formik';
import { makeStyles } from 'tss-react/mui';

import { LabelValue, ServiceDetailGroup } from 'app/components';
import { SwitchMatchResult } from 'app/types/notifications';
import { SwitchOrderFull } from 'app/types/switchOrder';
import { getServiceDetailsDataFromMatchResults } from 'app/utils/service-utils';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: 4
  },
  wrapperActive: {
    borderColor: theme.palette.primary.main
  },
  wrapperRadio: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: 'white'
  },
  wrapperRadioActive: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderRightColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  },
  content: {
    padding: theme.spacing(2)
  }
}));

interface ServiceOptionProps {
  matchResult: SwitchMatchResult;
  name: string;
  order: SwitchOrderFull;
}

export const FormikServiceOption: FunctionComponent<ServiceOptionProps> = ({
  matchResult,
  name,
  order
}) => {
  const { classes } = useStyles();
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field] = useField(name);

  const isChecked = field.value === matchResult.switchOrderReference;

  const handleChange = () => {
    setFieldTouched(field.name, true);
    setFieldValue(field.name, matchResult.switchOrderReference);
  };

  return (
    <Grid
      container
      className={clsx(classes.wrapper, isChecked && classes.wrapperActive)}
      onClick={handleChange}
    >
      <Grid item className={clsx(classes.wrapperRadio, isChecked && classes.wrapperRadioActive)}>
        <Radio
          size='small'
          checked={isChecked}
          onChange={handleChange}
          value={matchResult.switchOrderReference}
          name={name}
        />
      </Grid>

      <Grid item xs container className={classes.content}>
        <LabelValue inline label='SOR'>
          {matchResult.switchOrderReference}
        </LabelValue>
        <Grid container>
          {!!matchResult && (
            <ServiceDetailGroup
              services={getServiceDetailsDataFromMatchResults(matchResult, order)}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
