import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { DataGrid, GridPaginationModel, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { ErrorBanner } from 'app/components';
import { DATE_TIME_FULL_FORMAT } from 'app/constants/dates';
import {
  ORDER_TABLE_FILTER_SEARCH_PARAM,
  ORDER_TABLE_PAGE_SEARCH_PARAM,
  ORDER_TABLE_PAGE_SIZE,
  ORDER_TABLE_PAGESIZE_SEARCH_PARAM,
  ORDER_TABLE_PROVIDER_TYPE_SEARCH_PARAM,
  ORDER_TABLE_ROW_HEIGHT,
  ORDER_TABLE_SORT_SEARCH_PARAM,
  ORDER_TABLE_SORTDIR_SEARCH_PARAM,
  ORDERS_TABLE_MIN_HEIGHT
} from 'app/constants/orders';
import { URLS } from 'app/constants/routes';
import { PROVIDER_ROLES } from 'app/constants/switchProcess';
import { useFetchOrders } from 'app/hooks/useOrderQueries';
import { FilterValue, SwitchOrderSummary } from 'app/types';
import { formatDateTime } from 'app/utils/date-utils';
import { formatError } from 'app/utils/error-utils';
import {
  buildSearchParamsFromFilterValues,
  convertStringToFilterValue,
  sanitiseFilterList
} from 'app/utils/filters-utils';

import { FilterByProvider } from './OrderFilters/FilterByProvider';
import { FilterControls, Filters } from './OrderFilters';
import { generateOrdersTableColumns } from './ordersTableColumns';

const useStyles = makeStyles()(theme => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerRight: {
    justifyContent: 'center'
  },
  filtersContainer: {
    padding: theme.spacing(3.5, 0)
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  providerTypeFilter: {
    display: 'flex',
    alignItems: 'baseline'
  },
  providerTypeFilterLabel: {
    marginRight: 12
  },
  refreshTimestamp: {
    textAlign: 'right',
    alignSelf: 'center'
  },
  gridContainer: {
    flex: '1 1 auto',

    '& .MuiDataGrid-root': {
      borderWidth: 0,

      '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
        outline: 0
      }
    }
  }
}));

export const Orders: FunctionComponent = () => {
  const { classes } = useStyles();
  // allows us to delay the query until we are ready (i.e. we have any filters from searchparams)
  const [enabled, setEnabled] = useState(false);

  // get any filters from urlsearchparams
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<FilterValue[]>([]);
  const [showProviderType, setShowProviderType] = useState<PROVIDER_ROLES>(PROVIDER_ROLES.GAINING);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: ORDER_TABLE_PAGE_SIZE
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'lastUpdatedAt', sort: 'desc' }
  ]);

  const queryOptions = useMemo(
    () => ({
      page: paginationModel.page,
      role: showProviderType,
      size: paginationModel.pageSize,
      sort: `${sortModel[0].field},${sortModel[0].sort},ignorecase`,
      filters: filters
    }),
    [filters, paginationModel.page, paginationModel.pageSize, showProviderType, sortModel]
  );

  const {
    isFetching,
    error: errorFetchOrders,
    data,
    dataUpdatedAt
  } = useFetchOrders(queryOptions, enabled);

  // While loading, data?.totalElements is undefined, so need to store in state
  const [rowCount, setRowCount] = useState(data?.totalElements || 0);
  useEffect(
    () =>
      setRowCount(prevRowCount =>
        data?.totalElements !== undefined ? data?.totalElements : prevRowCount
      ),
    [data, data?.totalElements, setRowCount]
  );

  const tableColumns = useMemo(() => {
    return generateOrdersTableColumns(showProviderType);
  }, [showProviderType]);

  const handleFilterChange = (newFilter: FilterValue) => {
    const filtersToUse = sanitiseFilterList([...filters], newFilter);
    setSearchParams(buildSearchParamsFromFilterValues(filtersToUse, searchParams));
    setFilters(filtersToUse);
  };

  const handleRemoveFilter = (index: number) => {
    filters.splice(index, 1);
    const filtersToUse = [...filters];
    setSearchParams(buildSearchParamsFromFilterValues(filtersToUse, searchParams));
    setFilters(filtersToUse);
  };

  const handleProviderTypeChange = (val: PROVIDER_ROLES) => {
    if (val !== null) {
      if (searchParams.has(ORDER_TABLE_PROVIDER_TYPE_SEARCH_PARAM)) {
        searchParams.set(ORDER_TABLE_PROVIDER_TYPE_SEARCH_PARAM, val);
      } else {
        searchParams.append(ORDER_TABLE_PROVIDER_TYPE_SEARCH_PARAM, val);
      }
      setSearchParams(searchParams);
      setShowProviderType(val);

      handlePagination({
        ...paginationModel,
        page: 0
      });
    }
  };

  const handlePagination = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel);
    searchParams.set(ORDER_TABLE_PAGE_SEARCH_PARAM, `${newModel.page}`);
    searchParams.set(ORDER_TABLE_PAGESIZE_SEARCH_PARAM, `${newModel.pageSize}`);
    setSearchParams(searchParams);
  };

  const handleSorting = (model: GridSortModel) => {
    setSortModel(model);
    searchParams.set(ORDER_TABLE_SORT_SEARCH_PARAM, `${model[0].field}`);
    searchParams.set(ORDER_TABLE_SORTDIR_SEARCH_PARAM, `${model[0].sort}`);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setFilters(
      searchParams
        .getAll(ORDER_TABLE_FILTER_SEARCH_PARAM)
        .map(val => convertStringToFilterValue(val))
        .filter((item): item is FilterValue => !!item)
    );

    // get the provider type value from the url or default to GAINING
    setShowProviderType(
      ((searchParams.get(ORDER_TABLE_PROVIDER_TYPE_SEARCH_PARAM) || '') in PROVIDER_ROLES
        ? searchParams.get(ORDER_TABLE_PROVIDER_TYPE_SEARCH_PARAM)!
        : PROVIDER_ROLES.GAINING) as PROVIDER_ROLES
    );

    setPaginationModel({
      page: Number(searchParams.get(ORDER_TABLE_PAGE_SEARCH_PARAM)) ?? 0,
      pageSize: searchParams.get(ORDER_TABLE_PAGESIZE_SEARCH_PARAM)
        ? Number(searchParams.get(ORDER_TABLE_PAGESIZE_SEARCH_PARAM))
        : ORDER_TABLE_PAGE_SIZE
    });

    setSortModel([
      {
        field: searchParams.get(ORDER_TABLE_SORT_SEARCH_PARAM) ?? 'lastUpdatedAt',
        sort: (searchParams.get(ORDER_TABLE_SORTDIR_SEARCH_PARAM) as GridSortDirection) ?? 'desc'
      }
    ]);

    setEnabled(true);
  }, [searchParams, setSearchParams]);

  return (
    <div className={classes.pageContainer}>
      <div className={classes.header}>
        <Typography variant='h3'>Orders</Typography>
        {showProviderType === PROVIDER_ROLES.GAINING && (
          <Stack className={classes.headerRight}>
            <Button component={RouterLink} to={`/${URLS.NEW_MATCH_REQUEST}`} variant='contained'>
              Create Match Request
            </Button>
          </Stack>
        )}
      </div>
      <div className={classes.filtersContainer}>
        <div className={classes.filters}>
          <FilterControls onFilterChange={handleFilterChange} />
          <FilterByProvider
            showProviderType={showProviderType}
            handleProviderTypeChange={handleProviderTypeChange}
          />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Filters filters={filters} onRemoveFilter={handleRemoveFilter} />
          </Grid>
          <Grid item xs={12} md={4} className={classes.refreshTimestamp}>
            <Typography variant='overline' display='block'>
              Last refresh &#64;{' '}
              {formatDateTime(
                dataUpdatedAt ? new Date(dataUpdatedAt) : new Date(),
                DATE_TIME_FULL_FORMAT
              )}
            </Typography>
          </Grid>
        </Grid>

        {!!errorFetchOrders && (
          <Box mt={2}>
            <ErrorBanner error={formatError(errorFetchOrders)} />
          </Box>
        )}
      </div>
      <Paper
        data-testid='orders-table'
        className={classes.gridContainer}
        sx={{ height: ORDERS_TABLE_MIN_HEIGHT }}
      >
        <DataGrid<SwitchOrderSummary>
          rows={data?.content || []}
          columns={tableColumns}
          columnBuffer={9}
          disableColumnMenu
          getRowId={row => row.switchId}
          hideFooterSelectedRowCount
          initialState={{
            pagination: {
              paginationModel
            }
          }}
          loading={isFetching}
          onPaginationModelChange={newModel => handlePagination(newModel)}
          onSortModelChange={model => handleSorting(model)}
          paginationModel={paginationModel}
          rowCount={rowCount}
          rowHeight={ORDER_TABLE_ROW_HEIGHT}
          paginationMode='server'
          pageSizeOptions={[10, 25, 50, 100]}
          sortingMode='server'
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
        />
      </Paper>
    </div>
  );
};
