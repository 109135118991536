import { FunctionComponent } from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { useField, useFormikContext } from 'formik';

import { READABLE_DATE_FORMAT } from 'app/constants';

interface FormikDatePickerProps extends DatePickerProps<Dayjs> {
  name: string;
}

export const FormikDatePicker: FunctionComponent<FormikDatePickerProps> = props => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(props.name);

  return (
    <DatePicker
      {...props}
      format={READABLE_DATE_FORMAT}
      value={field.value}
      onChange={value => {
        setFieldTouched(field.name, true);
        setFieldValue(field.name, value);
      }}
      slotProps={{
        textField: {
          size: 'small',
          variant: 'standard',
          fullWidth: true,
          error: meta.touched && Boolean(meta.error),
          helperText: meta.touched && meta.error
        }
      }}
    />
  );
};
