import { FunctionComponent, useEffect, useMemo } from 'react';
import { Grid, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import {
  AdditionalDetailsPanel,
  CustomerDetailsPanel,
  NotFoundFiller,
  OrderDetailsPageHeader,
  OrderHistoryPanel,
  PageLoadingIndicator,
  ProviderDetailsPanel,
  ServicesLosingPanel,
  ServicesPanel,
  SwitchMatchRequestServicesPanel
} from 'app/components';
import { NOTIFICATIONS, PROVIDER_ROLES, SWITCH_STATES, URLS } from 'app/constants';
import { useFetchOrder, useFetchOrderNotifications } from 'app/hooks/useOrderQueries';
import { MatchConfirmation, SwitchingImplications } from 'app/types';
import {
  checkServicesDataOpenOrderTypes,
  getServicesDataFromMatchConfirmation,
  getServicesDataFromSwitchingImplications,
  getSORFromNotifications
} from 'app/utils/service-utils';

const useStyles = makeStyles()(theme => ({
  row: {
    marginBottom: theme.spacing(2)
  },
  sameHeightRow: {
    display: 'flex',

    '& > * > *': {
      height: '100%'
    }
  },
  lastColumn: {
    width: '360px'
  }
}));

export const OrderDetails: FunctionComponent = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const params = useParams();

  const queryOptions = useMemo(
    () => ({
      switchId: params.id || ''
    }),
    [params.id]
  );

  const {
    isFetching: isOrderFetching,
    data: orderData,
    isError: isOrderError
  } = useFetchOrder(queryOptions);

  const {
    isFetching: isNotificationsFetching,
    data: notificationData,
    isError: isNotificationsError
  } = useFetchOrderNotifications(
    {
      switchId: orderData?.switchId || ''
    },
    !isOrderFetching && !!orderData
  );

  const isFetching = useMemo(
    () => isOrderFetching || isNotificationsFetching,
    [isNotificationsFetching, isOrderFetching]
  );

  const servicesData = useMemo(() => {
    try {
      if (orderData?.role === PROVIDER_ROLES.GAINING && !isFetching) {
        const notification = (notificationData || []).find(
          val => val.notificationType === NOTIFICATIONS.MatchConfirmation
        );
        return notification
          ? getServicesDataFromMatchConfirmation(
              notification.content as MatchConfirmation,
              orderData
            )
          : undefined;
      } else if (orderData?.role === PROVIDER_ROLES.LOSING && !isFetching) {
        const notification = (notificationData || []).find(
          val => val.notificationType === NOTIFICATIONS.SwitchingImplications
        );
        return notification
          ? getServicesDataFromSwitchingImplications(
              (notification.content || {}) as SwitchingImplications,
              orderData,
              getSORFromNotifications(notificationData)
            )
          : undefined;
      } else {
        return undefined;
      }
    } catch (e) {
      return undefined;
    }
  }, [isFetching, notificationData, orderData]);

  const { servicesDataDetails, implicationsSent, customFields } = useMemo(() => {
    return {
      servicesDataDetails: servicesData && 'details' in servicesData ? servicesData?.details : [],
      implicationsSent: servicesData?.implicationsSent,
      customFields: servicesData?.customFields
    };
  }, [servicesData]);

  const [hasOpenOrderTypeCease, hasOpenOrderTypeModify] = useMemo(
    () => checkServicesDataOpenOrderTypes(servicesDataDetails),
    [servicesDataDetails]
  );

  useEffect(() => {
    if (orderData) {
      if (
        orderData.state === SWITCH_STATES.MATCH_CONFIRMED &&
        orderData.role === PROVIDER_ROLES.GAINING
      ) {
        navigate(`/${URLS.GENERATE_ORDER.replace(':id', orderData.switchId)}`);
      }
    }
  }, [navigate, orderData]);

  return (
    <div data-testid='order-details'>
      <OrderDetailsPageHeader
        isFetching={isFetching}
        data={orderData}
        notificationData={notificationData}
        hasOpenOrderTypeCease={hasOpenOrderTypeCease}
        hasOpenOrderTypeModify={hasOpenOrderTypeModify}
      />
      {isFetching && <PageLoadingIndicator />}
      {isOrderError && (
        <NotFoundFiller message='The requested switch order could not be found. Please return to the list view.' />
      )}
      {!isFetching && !isOrderError && !!orderData && (
        <>
          <Grid container spacing={2} className={clsx(classes.row, classes.sameHeightRow)}>
            <Grid item xs>
              <CustomerDetailsPanel order={orderData} />
            </Grid>
            <Grid item xs>
              <ProviderDetailsPanel order={orderData} implicationsSent={implicationsSent || []} />
            </Grid>
            <Grid item className={classes.lastColumn}>
              <AdditionalDetailsPanel order={orderData} customFields={customFields} />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.row}>
            <Grid item xs>
              <Stack spacing={2}>
                {orderData.services && (
                  <SwitchMatchRequestServicesPanel services={orderData.services} />
                )}
                {servicesData && 'details' in servicesData && (
                  <ServicesPanel order={orderData} serviceDetails={servicesData.details} />
                )}
                {orderData?.role === PROVIDER_ROLES.LOSING && (
                  <Stack spacing={2}>
                    {servicesData && 'matchedServicesData' in servicesData && (
                      <ServicesLosingPanel
                        title='Services'
                        servicesData={servicesData.matchedServicesData}
                      />
                    )}
                    {servicesData && 'otherServicesData' in servicesData && (
                      <ServicesLosingPanel
                        title='Retained Services'
                        servicesData={servicesData.otherServicesData}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            </Grid>
            <Grid item className={classes.lastColumn}>
              <OrderHistoryPanel isError={isNotificationsError} notifications={notificationData} />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
