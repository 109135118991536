import { useState } from 'react';

interface UseModalResult {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

export const useModal = (): UseModalResult => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return {
    open,
    handleOpen,
    handleClose
  };
};
