import { FunctionComponent, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import ModalMui from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import { ErrorBanner, ErrorBannerProps } from 'app/components/Errors';

const useStyles = makeStyles()(theme => ({
  cover: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    minWidth: 400,
    transform: 'translate(-50%, -50%)'
  },
  wrapper: {
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper
  },
  container: {
    padding: theme.spacing(2)
  },
  actions: {
    padding: theme.spacing(2, 0, 0)
  },
  content: {
    padding: theme.spacing(2, 0, 2)
  }
}));

interface ModalProps extends PropsWithChildren {
  isOpen: boolean;
  modalTitle?: string;
  modalError?: ErrorBannerProps['error'];
  onClose?: () => void;
  width?: string;
}

export const ModalContent: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();

  return <Box className={classes.content}>{children}</Box>;
};

export const ModalActions: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  return (
    <Stack
      className={classes.actions}
      direction='row'
      justifyContent='flex-end'
      alignItems='flex-end'
      spacing={1}
    >
      {children}
    </Stack>
  );
};

export const Modal: FunctionComponent<ModalProps> = ({
  isOpen,
  onClose,
  modalTitle,
  modalError,
  width,
  children
}) => {
  const { classes } = useStyles();

  return (
    <ModalMui open={isOpen} onClose={onClose}>
      <Box className={classes.cover} width={width}>
        <Box className={clsx(classes.wrapper)}>
          {modalError && <ErrorBanner error={modalError} />}

          <Box className={clsx(classes.container)}>
            {modalTitle && <Typography variant='h6'>{modalTitle}</Typography>}
            {children}
          </Box>
        </Box>
      </Box>
    </ModalMui>
  );
};
