import { FunctionComponent } from 'react';
import TextField from '@mui/material/TextField';
import { useField, useFormikContext } from 'formik';

interface FormikTextFieldProps {
  name: string;
  label: string;
  helperText?: string;
  type?: string;
  required?: boolean;
}

export const FormikTextField: FunctionComponent<FormikTextFieldProps> = ({
  name,
  label,
  helperText,
  type = 'text',
  required = false
}) => {
  const { handleBlur, handleChange } = useFormikContext();
  const [field, meta] = useField(name);
  return (
    <TextField
      name={name}
      label={label}
      type={type}
      required={required}
      autoComplete='off'
      fullWidth
      variant='standard'
      value={field.value || ''}
      onChange={handleChange}
      onBlur={handleBlur}
      error={meta.touched && Boolean(meta.error)}
      helperText={
        <>
          {helperText} {meta.touched && meta.error && `${helperText ? '- ' : ''}${meta.error}`}
        </>
      }
    />
  );
};
