import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, LinearProgress, Stack, Typography } from '@mui/material';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { useDropzone } from 'react-dropzone';
import axios, { CancelTokenSource } from 'axios';
import { makeStyles } from 'tss-react/mui';

import { Modal, ModalActions, useModal } from 'app/components';
import { DATA_IMPORTS_API_ROOT } from 'app/constants';
import theme from 'app/theme';
import { ErrorResponse } from 'app/types';
import { formatError } from 'app/utils/error-utils';

const useStyles = makeStyles()(theme => ({
  dropZone: {
    cursor: 'pointer',
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: theme.palette.grey['300'],
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    padding: theme.spacing(4)
  },
  dropZoneError: {
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.error.main,
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    padding: theme.spacing(4),
    backgroundColor: '#fdf7f7'
  },
  selectText: {
    span: {
      color: theme.palette.primary.main,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.primary.main
    }
  }
}));

export const ImportCustomerData: React.FC = () => {
  const { classes } = useStyles();
  const { open, handleOpen, handleClose } = useModal();

  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [source, setSource] = useState<CancelTokenSource | null>(null);
  const [errorRequest, setErrorRequest] = useState<ErrorResponse>();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      if (selectedFile.type === 'application/zip') {
        setFile(selectedFile);
        setError('');
      } else {
        setError('Unsupported file type.');
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'application/zip': ['.zip'] },
    multiple: false
  });

  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);
    const cancelTokenSource = axios.CancelToken.source();
    setSource(cancelTokenSource);

    try {
      const formData = new FormData();
      formData.append('file', file);

      await axios.post(DATA_IMPORTS_API_ROOT, formData, {
        cancelToken: cancelTokenSource?.token
      });

      setUploading(false);
      handleClose();
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Upload cancelled');
      } else {
        setErrorRequest(error as ErrorResponse);
      }
      setUploading(false);
    }
  };

  const handleSelectDifferentFile = () => {
    setFile(null);
    setErrorRequest(undefined);
  };

  useEffect(() => {
    if (!open) {
      if (source) {
        source.cancel('Operation cancelled by the user.');
      }
      setFile(null);
      setError('');
      setUploading(false);
    }
  }, [open, source]);

  return (
    <>
      <Button color='primary' variant='contained' onClick={handleOpen}>
        Import Customer Data
      </Button>

      <Modal
        width={'500px'}
        modalTitle='Import Customer Data'
        modalError={errorRequest && formatError(errorRequest)}
        isOpen={open}
        onClose={handleClose}
      >
        {file ? (
          <Stack
            mt={2}
            mx={2}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={1}
          >
            <UploadFileOutlinedIcon color={error ? 'error' : 'primary'} />
            <Stack direction='column'>
              <Typography variant='body1'>File: {file.name}</Typography>
              <Typography variant='body2' color='grey'>
                Size: {(file.size / 1024).toFixed(2)} KB
              </Typography>
            </Stack>
            <Button variant='outlined' size='small' onClick={handleSelectDifferentFile}>
              Select a Different File
            </Button>
          </Stack>
        ) : (
          <Box
            mt={2}
            {...getRootProps()}
            className={error ? classes.dropZoneError : classes.dropZone}
          >
            <input {...getInputProps()} />
            <UploadFileOutlinedIcon color={error ? 'error' : 'primary'} />
            <Box my={theme.spacing(1)} className={classes.selectText}>
              <span>Select ZIP file to upload</span> or drag and drop
            </Box>
            {error && <Typography color='error'>{error}</Typography>}
          </Box>
        )}
        {uploading && (
          <Box mt={4}>
            <LinearProgress />
          </Box>
        )}
        <ModalActions>
          <Button onClick={handleClose} disabled={uploading}>
            Close
          </Button>
          <Button
            data-testid='upload-file'
            variant='contained'
            onClick={handleUpload}
            disabled={!file || uploading}
          >
            Upload
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};
