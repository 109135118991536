import { useMemo } from 'react';
import axios, { AxiosInstance } from 'axios';

import { useAppConfig } from './useAppConfig';
import { useAuth } from './useAuth';

export const useAxiosWrapper = (): AxiosInstance => {
  const { apiUrl } = useAppConfig();
  const auth = useAuth();

  return useMemo(() => {
    return axios.create({
      baseURL: process.env.REACT_APP_ENABLE_MOCK_SERVICE_WORKER === 'true' ? '' : apiUrl,
      headers: {
        Authorization: `Bearer ${auth.user?.access_token}`
      }
    });
  }, [apiUrl, auth.user?.access_token]);
};
