import { FunctionComponent, useState } from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalActions, ModalContent, useModal } from 'app/components';
import { SWITCH_STATES } from 'app/constants';
import { useCancelOrderMutation } from 'app/hooks/useOrderQueries';
import { ErrorResponse, SwitchOrderFull } from 'app/types';
import { formatError } from 'app/utils/error-utils';

export const CancelOrder: FunctionComponent<{ order: SwitchOrderFull }> = ({ order }) => {
  const { mutate, isLoading } = useCancelOrderMutation();
  const navigate = useNavigate();
  const { open, handleOpen, handleClose } = useModal();
  const [error, setError] = useState<ErrorResponse | undefined>();

  const handleSubmit = async () => {
    setError(undefined);

    mutate(
      {
        id: order.switchId
      },
      {
        onSuccess: () => {
          handleClose();
          navigate(0);
        },
        onError: err => {
          setError(formatError(err));
        }
      }
    );
  };

  return (
    <>
      <Button
        color='error'
        variant='outlined'
        disabled={order.state === SWITCH_STATES.UPDATE_REQUESTED}
        onClick={handleOpen}
      >
        Cancel Order
      </Button>

      <Modal
        modalTitle='Confirm Order Cancellation'
        modalError={error}
        isOpen={open}
        onClose={handleClose}
      >
        <ModalContent>
          <Typography variant='body1'>Are you sure you want to cancel this order?</Typography>
          <br />
          <Typography>This action cannot be reversed.</Typography>
        </ModalContent>

        <ModalActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            color='error'
            data-testid='cancel-order'
            variant='contained'
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Cancel Order'}
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};
