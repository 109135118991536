import { createContext, PropsWithChildren, useContext } from 'react';

import { AppConfig } from 'app/types/appConfig';

const AppConfigContext = createContext<AppConfig>({} as AppConfig);
AppConfigContext.displayName = 'AppConfigContext';

type AppConfigProviderProps = PropsWithChildren & {
  config: AppConfig;
};

export const AppConfigProvider: React.FC<AppConfigProviderProps> = ({ config, children }) => (
  <AppConfigContext.Provider value={{ ...config }}>{children}</AppConfigContext.Provider>
);

export const useAppConfig = () => useContext(AppConfigContext);
