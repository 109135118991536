import { FunctionComponent, useEffect, useState } from 'react';
import { LinearProgress, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Modal } from 'app/components';
import { MATCH_REQUEST_DELAY_MESSAGE, MATCH_REQUEST_MAX_TIMEOUT } from 'app/constants/orders';
import { URLS } from 'app/constants/routes';

const useStyles = makeStyles()(theme => ({
  container: {
    width: '500px',
    padding: theme.spacing(2.5, 8)
  },
  progress: {
    marginBottom: theme.spacing(4)
  },
  message: {
    marginTop: theme.spacing(2)
  }
}));

interface MatchRequestLoadingModalProps {
  show: boolean;
  onErrorTimeout: () => void;
}

export const MatchRequestLoadingModal: FunctionComponent<MatchRequestLoadingModalProps> = ({
  show,
  onErrorTimeout
}) => {
  const { classes } = useStyles();

  const [showMessage, setShowMessage] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setCounter(c => c + 1);

        // after a delay show a message to say this is taking longer than expected
        if (!showMessage && counter > MATCH_REQUEST_DELAY_MESSAGE) {
          setShowMessage(true);
        }

        // after a much longer delay, show an error
        if (counter > MATCH_REQUEST_MAX_TIMEOUT) {
          onErrorTimeout();
        }
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    } else {
      // modal is not being shown, so ensure things are reset
      setShowMessage(false);
      setCounter(0);
    }
  }, [show, onErrorTimeout, setShowMessage, counter, showMessage]);

  return (
    <Modal isOpen={show}>
      <div className={classes.container} data-testid='match-request-loading-modal'>
        <LinearProgress className={classes.progress} />
        <Typography variant='h6'>Match request processing.</Typography>
        <Typography variant='overline'>Elapsed time: {counter} seconds.</Typography>

        <Typography className={classes.message}>
          Please wait or return to the{' '}
          <Link component={RouterLink} to={`/${URLS.ORDERS}`}>
            Orders list
          </Link>
          .
        </Typography>

        <Typography className={classes.message}>
          Navigating away from this page will NOT disrupt the match request process.
        </Typography>

        {showMessage && (
          <Typography className={classes.message}>
            The match request is taking longer than expected.
          </Typography>
        )}
      </div>
    </Modal>
  );
};
