import { FunctionComponent, ReactNode } from 'react';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(6)
  },
  errorPaper: {
    padding: theme.spacing(3)
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}));

export const NotFoundFiller: FunctionComponent<{ message: ReactNode }> = ({ message }) => {
  const { classes } = useStyles();

  return (
    <div data-testid='not-found-filler' className={classes.container}>
      <Paper className={classes.errorPaper}>
        <Typography variant='h5' className={classes.title}>
          Not Found
        </Typography>
        <Typography variant='body1'>
          {message ?? 'The page you requested could not be found.'}
        </Typography>
      </Paper>
    </div>
  );
};
