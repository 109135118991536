import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';

import { LabelValue, Panel } from 'app/components';
import { PROVIDER_ROLES } from 'app/constants/switchProcess';
import { ImplicationsData, SwitchOrderFull } from 'app/types';
import { formatServiceImplications } from 'app/utils/format-utils';
import { getProviderLabel } from 'app/utils/provider-utils';

export const ProviderDetailsPanel: FunctionComponent<{
  order: SwitchOrderFull;
  implicationsSent: ImplicationsData[];
}> = ({ order, implicationsSent }) => (
  <Panel title='Provider Details'>
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item xs>
          {order.role === PROVIDER_ROLES.GAINING ? (
            <LabelValue label='LRCP'>{getProviderLabel(order.lrcp)}</LabelValue>
          ) : (
            <LabelValue label='GRCP'>{getProviderLabel(order.grcp)}</LabelValue>
          )}
        </Grid>
        {order.role === PROVIDER_ROLES.GAINING && (
          <Grid item xs>
            <LabelValue label='Account Ref'>{order.accountRef}</LabelValue>
          </Grid>
        )}
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs>
          <LabelValue label='Switching Implications'>
            {formatServiceImplications(implicationsSent)}
          </LabelValue>
        </Grid>
      </Grid>
    </Grid>
  </Panel>
);
