import { Fragment, FunctionComponent, useMemo } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';

import { ServiceDetailsData } from 'app/types/serviceDetails';
import { pairServices } from 'app/utils/service-utils';

import { ServiceDetailItem } from './ServiceDetailItem';

const useStyles = makeStyles()(theme => ({
  divider: {
    width: `calc(100% - ${theme.spacing(2)})`,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(-1),

    '&:last-of-type': {
      display: 'none'
    }
  }
}));

export const ServiceDetailGroup: FunctionComponent<{
  services: ServiceDetailsData[];
}> = ({ services }) => {
  const { classes } = useStyles();

  const pairs = useMemo(() => pairServices(services), [services]);

  return (
    <Grid container item columnSpacing={2} rowSpacing={0}>
      {pairs.map(({ broadband, voice }, index) => (
        <Fragment key={`service-${index}`}>
          <ServiceDetailItem service={broadband} />
          <ServiceDetailItem service={voice} rightColumn />
          <Divider variant='fullWidth' className={classes.divider} />
        </Fragment>
      ))}
    </Grid>
  );
};
