import { getDataImportStateValueLabel } from 'app/utils/imports-utils';

import { DATA_IMPORT_STATES } from './dataImport';

export const SORTED_DATA_IMPORT_STATES = [
  DATA_IMPORT_STATES.SCHEDULED,
  DATA_IMPORT_STATES.STARTED,
  DATA_IMPORT_STATES.COMPLETED,
  DATA_IMPORT_STATES.FAILED
];

export const DATA_IMPORT_STATES_AS_OPTIONS = SORTED_DATA_IMPORT_STATES.map(state => ({
  label: getDataImportStateValueLabel(state),
  value: state
}));
