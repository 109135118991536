export enum URLS {
  NEW_MATCH_REQUEST = 'create-match-request',
  AMEND_MATCH_REQUEST = 'create-match-request/:id',
  GENERATE_ORDER = 'match-request/:id',
  ORDERS = 'orders',
  ORDER_DETAILS = 'orders/:id',
  DATA_IMPORT = 'data-import'
}

export const SWITCH_ORDERS_API_ROOT = '/switch/v1/switches';
export const RCPS_API_ROOT = '/switch/v1/providers';
export const DATA_IMPORTS_API_ROOT = '/data/v1/imports';
export const ERRORS_CSV_DOWNLOAD_API = '/data/v1/imports/:id/errors-csv-download';
