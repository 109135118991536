import { Link, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from 'dayjs';

import { OrderAddress, OrderProviderType, OrderState } from 'app/components/Orders';
import {
  DATE_TIME_FORMAT,
  PROVIDER_ROLES,
  READABLE_DATE_FORMAT,
  SWITCH_STATES,
  URLS
} from 'app/constants';
import { SwitchOrderSummary } from 'app/types/switchOrder';
import { formatDate } from 'app/utils/date-utils';

export const generateOrdersTableColumns = (providerType: PROVIDER_ROLES): GridColDef[] => [
  {
    field: 'type',
    filterable: false,
    headerName: 'Type',
    renderCell: () => <OrderProviderType type={providerType} />,
    sortable: false,
    width: 60
  },
  {
    field: 'reference',
    filterable: false,
    flex: 0.75,
    headerName: providerType === PROVIDER_ROLES.GAINING ? 'UI Reference' : 'Account Ref',
    minWidth: 120,
    valueGetter: (params: GridValueGetterParams<SwitchOrderSummary>) => {
      return providerType === PROVIDER_ROLES.LOSING
        ? params.row.state !== SWITCH_STATES.MATCH_FAILED
          ? params.row.accountRef
          : ''
        : params.row.uiReference;
    }
  },
  {
    field: 'name',
    filterable: false,
    flex: 1,
    headerName: 'Surname',
    minWidth: 160,
    renderCell: (params: GridRenderCellParams<SwitchOrderSummary>) => {
      let path = `/${URLS.ORDER_DETAILS.replace(':id', params.row.switchId)}`;
      if (
        providerType === PROVIDER_ROLES.GAINING &&
        params.row.state === SWITCH_STATES.MATCH_CONFIRMED
      ) {
        path = `/${URLS.GENERATE_ORDER.replace(':id', params.row.switchId)}`;
      }

      return (
        <Link component={RouterLink} to={path} state={{ fromListPage: true }}>
          <Typography variant={params.row.customerSurname ? 'body2' : 'emptyValue'}>
            {params.row.customerSurname || 'Unavailable'}
          </Typography>
        </Link>
      );
    },
    sortable: false
  },
  {
    field: 'address',
    filterable: false,
    flex: 1,
    headerName: 'Address',
    minWidth: 210,
    renderCell: (params: GridRenderCellParams<SwitchOrderSummary>) => (
      <OrderAddress address={params.row.customerAddress} />
    ),
    sortable: false
  },
  {
    field: 'rcp',
    filterable: false,
    headerName: providerType === PROVIDER_ROLES.GAINING ? 'LRCP' : 'GRCP',
    sortable: false,
    valueGetter: (params: GridValueGetterParams<SwitchOrderSummary>) =>
      providerType === PROVIDER_ROLES.GAINING ? params.row.lrcp.rcpId : params.row.grcp.rcpId,
    width: 80
  },
  {
    field: 'switchOrderReference',
    filterable: false,
    flex: 2,
    headerName: 'Switch Order Reference',
    minWidth: 280,
    renderCell: (params: GridRenderCellParams<SwitchOrderSummary>) => (
      <Typography variant='body2' noWrap={true} title={params.row.switchOrderReference}>
        {params.row.switchOrderReference}
      </Typography>
    ),
    sortable: false
  },
  {
    field: 'effectiveDate',
    filterable: false,
    headerName: 'Effective Date',
    sortable: true,
    width: 120,
    valueGetter: (params: GridValueGetterParams<SwitchOrderSummary>) =>
      params.row?.effectiveDate && formatDate(params.row?.effectiveDate, READABLE_DATE_FORMAT)
  },
  {
    field: 'state',
    filterable: false,
    flex: 0.75,
    headerName: 'State',
    minWidth: 190,
    renderCell: (params: GridRenderCellParams<SwitchOrderSummary>) => (
      <OrderState state={params.row?.state} />
    ),
    sortable: false
  },
  {
    field: 'lastUpdatedAt',
    filterable: false,
    headerName: 'Last Updated',
    sortable: true,
    width: 135,
    valueGetter: (params: GridValueGetterParams<SwitchOrderSummary>) =>
      params.row?.lastUpdatedAt && dayjs(params.row?.lastUpdatedAt).format(DATE_TIME_FORMAT)
  }
];
