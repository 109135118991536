import { FunctionComponent } from 'react';
import { Box, Paper, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { makeStyles } from 'tss-react/mui';

import { PROVIDER_ROLES } from 'app/constants/switchProcess';
import { ReactComponent as GainingIcon } from 'assets/icon-gaining.svg';
import { ReactComponent as LosingIcon } from 'assets/icon-losing.svg';

const useStyles = makeStyles()(theme => ({
  providerTypeFilter: {
    display: 'flex',
    alignItems: 'center'
  },
  providerTypeFilterLabel: {
    marginRight: 12
  },

  contained: {
    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'white'
    },
    '&.Mui-error.Mui-selected, &.Mui-error.Mui-selected:hover': {
      backgroundColor: theme.palette.error.main
    },
    '&.MuiToggleButton-success.Mui-selected, &.MuiToggleButton-success.Mui-selected:hover': {
      backgroundColor: theme.palette.success.main
    }
  }
}));

export interface FilterByProviderProps {
  showProviderType: PROVIDER_ROLES;
  handleProviderTypeChange: (val: PROVIDER_ROLES) => void;
}

export const FilterByProvider: FunctionComponent<FilterByProviderProps> = ({
  showProviderType,
  handleProviderTypeChange
}) => {
  const { classes } = useStyles();
  return (
    <div className={classes.providerTypeFilter} data-testid='providerTypeFilter'>
      <Typography variant='body1' className={classes.providerTypeFilterLabel}>
        Show by Type:
      </Typography>
      <Paper>
        <ToggleButtonGroup
          color='primary'
          value={showProviderType}
          exclusive
          size='small'
          onChange={(e, val) => handleProviderTypeChange(val as PROVIDER_ROLES)}
          aria-label='Provider Type'
        >
          <ToggleButton
            color='success'
            className={classes.contained}
            value={PROVIDER_ROLES.GAINING}
            data-testid='providerTypeFilter-gaining'
          >
            <SvgIcon fontSize='small' component={GainingIcon} />
            <Box ml={0.5}>{PROVIDER_ROLES.GAINING}</Box>
          </ToggleButton>
          <ToggleButton
            color='error'
            className={classes.contained}
            value={PROVIDER_ROLES.LOSING}
            data-testid='providerTypeFilter-losing'
          >
            <SvgIcon fontSize='small' component={LosingIcon} />
            <Box ml={0.5}>{PROVIDER_ROLES.LOSING}</Box>
          </ToggleButton>
        </ToggleButtonGroup>
      </Paper>
    </div>
  );
};
