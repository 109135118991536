import { FunctionComponent } from 'react';
import Container from '@mui/material/Container';
import { makeStyles } from 'tss-react/mui';

import sonalakeLogo from 'assets/sonalake-logo-black.svg';

const useStyles = makeStyles()(theme => ({
  footer: {
    borderTop: '1px solid #C4C4C4',
    justifyContent: 'end',
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25)
  },
  copyrightContainer: {
    textAlign: 'right'
  },
  copyright: {
    color: '#A9A9A9',
    fontSize: '12px',
    fontWeight: '500',
    margin: 0
  }
}));

export const Footer: FunctionComponent = () => {
  const { classes } = useStyles();

  return (
    <Container maxWidth={false} className={classes.footer}>
      <div className={classes.copyrightContainer}>
        <img src={sonalakeLogo} width='110.5px' height='16px' />
        <p className={classes.copyright}>
          pivOTS FE v{process.env.REACT_APP_VERSION} &copy; Sonalake {new Date().getFullYear()}
        </p>
      </div>
    </Container>
  );
};
