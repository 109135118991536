import { FunctionComponent, PropsWithChildren } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  panel: {
    padding: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(1)
  }
}));

export interface PanelProps extends PropsWithChildren {
  title?: string;
}

export const Panel: FunctionComponent<PanelProps> = ({ title, children }) => {
  const { classes } = useStyles();
  return (
    <Paper className={classes.panel}>
      <Typography variant='h5' className={classes.title}>
        {title}
      </Typography>
      {children}
    </Paper>
  );
};
