import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '48px'
  }
}));

export const UnauthorisedPage: FunctionComponent = () => {
  const { classes } = useStyles();

  return (
    <Box title='Unauthorised' data-testid='unauthorised' className={classes.container}>
      <Typography variant='h1'>Unauthorised Access</Typography>
      <Typography variant='body1'>
        You are not authorised to view this page. Please check your login details and try again.
      </Typography>
    </Box>
  );
};
