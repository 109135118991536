import { FunctionComponent, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { Form, Formik } from 'formik';

import { ErrorBanner, FormikDatePicker, Panel } from 'app/components';
import { URLS } from 'app/constants';
import { useCreateOrderMutation } from 'app/hooks/useOrderQueries';
import { ErrorResponse, SwitchMatchDetails, SwitchOrderFull } from 'app/types';
import { formatDate } from 'app/utils/date-utils';
import { formatError } from 'app/utils/error-utils';

import { FormikServiceOption } from './FormikServiceOption';
import { validationGenerateOrder } from './validation';

interface FormikValuesProps {
  plannedSwitchDate: Dayjs;
  selectedSwitchOrderReference: string;
}

export const ServicesPanel: FunctionComponent<{
  order: SwitchOrderFull;
  switchMatchDetails?: SwitchMatchDetails;
}> = ({ order, switchMatchDetails }) => {
  const { mutate } = useCreateOrderMutation();
  const [error, setError] = useState<ErrorResponse | undefined>();
  const navigate = useNavigate();

  const handleExit = () => navigate(`/${URLS.ORDERS}`);

  const handleSubmit = (values: FormikValuesProps) => {
    setError(undefined);

    mutate(
      {
        switchId: order.switchId,
        selectedSwitchOrderReference: values.selectedSwitchOrderReference,
        plannedSwitchDate: formatDate(values.plannedSwitchDate)
      },
      {
        onSuccess: () => {
          navigate(`/${URLS.ORDERS}`);
        },
        onError: err => {
          setError(formatError(err));
        }
      }
    );
  };

  const handleAmendRequest = () => {
    navigate(`/${URLS.AMEND_MATCH_REQUEST.replace(':id', order.switchId)}`);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        plannedSwitchDate: null as unknown as Dayjs,
        selectedSwitchOrderReference: switchMatchDetails?.matchResult.switchOrderReference || ''
      }}
      validationSchema={validationGenerateOrder}
      onSubmit={handleSubmit}
    >
      {({ isValid, isSubmitting }) => (
        <Form data-testid='service-form'>
          <Panel title='Service Options'>
            <Box mb={5} width={300}>
              <FormikDatePicker
                disablePast
                name='plannedSwitchDate'
                label='Planned Switch Date *'
              />
            </Box>

            <RadioGroup aria-labelledby='service-options'>
              {switchMatchDetails && (
                <FormikServiceOption
                  matchResult={switchMatchDetails?.matchResult}
                  name='selectedSwitchOrderReference'
                  order={order}
                />
              )}

              {!!switchMatchDetails?.alternativeSwitchOrders?.length && (
                <Box m={2} textAlign='center'>
                  <Typography variant='overline'>ALTERNATIVES</Typography>
                </Box>
              )}

              {switchMatchDetails?.alternativeSwitchOrders?.map(({ matchResult }) => (
                <Box mb={2} key={matchResult.switchOrderReference}>
                  <FormikServiceOption
                    matchResult={matchResult}
                    name='selectedSwitchOrderReference'
                    order={order}
                  />
                </Box>
              ))}
            </RadioGroup>

            {!!error && (
              <Box my={2}>
                <ErrorBanner error={error} />
              </Box>
            )}
          </Panel>

          <Box my={2}>
            <Stack direction='row' justifyContent='flex-end' alignItems='flex-end' spacing={1}>
              <Button onClick={handleExit}>Exit</Button>
              <Button color='warning' variant='outlined' onClick={handleAmendRequest}>
                Amend Match Request
              </Button>
              <Button
                data-testid='generate-switch-order-submit'
                type='submit'
                variant='contained'
                disabled={!isValid || isSubmitting}
              >
                Generate switch order
              </Button>
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
