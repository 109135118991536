import 'dayjs/locale/en-gb';

import { FunctionComponent } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

import { IdentityProvider } from './hooks/useIdentity';
import { RcpProvider } from './hooks/useRcps';
import { AppLayout } from './components';
import theme from './theme';

export const muiCache = createCache({
  key: 'mui',
  prepend: true
});

const App: FunctionComponent = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  });

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
            <BrowserRouter>
              <IdentityProvider>
                <RcpProvider>
                  <CssBaseline />
                  <AppLayout />
                </RcpProvider>
              </IdentityProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
