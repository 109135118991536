import * as yup from 'yup';

export const validationGenerateOrder = yup.object({
  selectedSwitchOrderReference: yup.string().required('Switch Order Reference is required'),
  plannedSwitchDate: yup
    .date()
    .typeError('Invalid date')
    .required('Planned Switch Date is required')
    .min(new Date().toDateString(), 'Planned Switch Date must be today or in the future')
});
