import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';

import { ORDER_TABLE_AUTO_REFRESH_DELAY } from 'app/constants';
import { SWITCH_ORDERS_API_ROOT } from 'app/constants/routes';
import { PROCESSES, PROVIDER_ROLES } from 'app/constants/switchProcess';
import { useAxiosWrapper } from 'app/hooks/useAxiosWrapper';
import { useIdentity } from 'app/hooks/useIdentity';
import {
  ErrorResponse,
  PaginatedResponse,
  SwitchOrderFull,
  SwitchOrderNotification,
  SwitchOrderSummary
} from 'app/types';
import { FilterValue } from 'app/types/filters';
import { PROVIDER_ROLES_TYPE } from 'app/types/typesFromEnums';
import { convertFilterValuesForAPI } from 'app/utils/filters-utils';

export interface FetchOrdersOptions {
  page?: number;
  role: PROVIDER_ROLES_TYPE;
  size?: number;
  sort?: string;
  filters?: FilterValue[];
}

// GRCP and LRCP
export const useFetchOrders = (
  { filters, ...options }: FetchOrdersOptions,
  enabled = true,
  refetchInterval = ORDER_TABLE_AUTO_REFRESH_DELAY
) => {
  const { rcpId } = useIdentity();

  const allFilters = [
    ...(filters || []).map(filter => ({
      ...filter,
      field:
        filter.field === 'rcpId'
          ? options.role === PROVIDER_ROLES.GAINING
            ? 'lrcpId'
            : 'grcpId'
          : filter.field
    })),
    { field: options.role === PROVIDER_ROLES.GAINING ? 'grcpId' : 'lrcpId', value: rcpId }
  ];

  const convertedFilters = convertFilterValuesForAPI(allFilters);

  const queryOptions = {
    ...options,
    ...convertedFilters,
    type: PROCESSES.OTS,
    sort: options.sort?.replace(
      'reference',
      options.role === PROVIDER_ROLES.GAINING ? 'uiReference' : 'accountRef'
    )
  };

  const axios = useAxiosWrapper();

  return useQuery({
    queryKey: ['orders', queryOptions],
    queryFn: () =>
      axios
        .get<PaginatedResponse<SwitchOrderSummary>>(SWITCH_ORDERS_API_ROOT, {
          params: queryOptions
        })
        .then(response => response.data),
    enabled,
    refetchInterval: (data, query) => (query.state.error ? 0 : refetchInterval),
    refetchIntervalInBackground: false
  });
};

export interface FetchOrderOptions {
  switchId: string;
}

// GRCP and LRCP
export const useFetchOrder = ({ switchId }: FetchOrderOptions, enabled: boolean = true) => {
  const axios = useAxiosWrapper();

  return useQuery({
    queryKey: ['orderDetails', switchId],
    queryFn: () =>
      axios
        .get<SwitchOrderFull>(`${SWITCH_ORDERS_API_ROOT}/${switchId}`)
        .then(response => response.data),
    enabled
  });
};

export interface FetchOrderNotificationsOptions {
  switchId: string;
}

// GRCP and LRCP
export const useFetchOrderNotifications = (
  { switchId }: FetchOrderNotificationsOptions,
  enabled = true,
  refetchInterval?: UseQueryOptions<
    SwitchOrderNotification[],
    unknown,
    SwitchOrderNotification[],
    string[]
  >['refetchInterval']
) => {
  const axios = useAxiosWrapper();

  return useQuery({
    queryKey: ['orderNotifications', switchId],
    queryFn: () =>
      axios
        .get<SwitchOrderNotification[]>(`${SWITCH_ORDERS_API_ROOT}/${switchId}/notifications`)
        .then(response => response.data),
    enabled,
    refetchInterval
  });
};

interface UpdateOrderMutationOptions {
  id: string;
  plannedSwitchDate: string;
}

// GRCP Only
export const useUpdateOrderMutation = () => {
  const axios = useAxiosWrapper();

  return useMutation<SwitchOrderFull, unknown, UpdateOrderMutationOptions>({
    mutationKey: 'updateOrderMutation',
    mutationFn: ({ id, ...requestBody }: UpdateOrderMutationOptions) =>
      axios
        .patch<SwitchOrderFull>(`${SWITCH_ORDERS_API_ROOT}/${id}`, {
          ...requestBody
        })
        .then(response => response.data)
        .catch((error: ErrorResponse | AxiosError) => {
          throw error;
        })
  });
};

interface TriggerSwitchMutationOptions {
  id: string;
  activationDate: string;
}

export const useTriggerSwitchMutation = () => {
  const axios = useAxiosWrapper();

  return useMutation<SwitchOrderFull, unknown, TriggerSwitchMutationOptions>({
    mutationKey: 'triggerSwitchMutation',
    mutationFn: ({ id, ...requestBody }: TriggerSwitchMutationOptions) =>
      axios
        .patch<SwitchOrderFull>(`${SWITCH_ORDERS_API_ROOT}/${id}/trigger`, {
          ...requestBody
        })
        .then(response => response.data)
        .catch((error: ErrorResponse | AxiosError) => {
          throw error;
        })
  });
};

interface TriggerSwitchConfirmMutationOptions {
  id: string;
}

export const useTriggerConfirmSwitchMutation = () => {
  const axios = useAxiosWrapper();

  return useMutation<SwitchOrderFull, unknown, TriggerSwitchConfirmMutationOptions>({
    mutationKey: 'triggerSwitchConfirmMutation',
    mutationFn: ({ id, ...requestBody }: TriggerSwitchConfirmMutationOptions) =>
      axios
        .patch<SwitchOrderFull>(`${SWITCH_ORDERS_API_ROOT}/${id}/trigger-confirm`, {
          ...requestBody
        })
        .then(response => response.data)
        .catch((error: ErrorResponse | AxiosError) => {
          throw error;
        })
  });
};

interface CancelOrderMutationOptions {
  id: string;
}

export const useCancelOrderMutation = () => {
  const axios = useAxiosWrapper();

  return useMutation<SwitchOrderFull, unknown, CancelOrderMutationOptions>({
    mutationKey: 'cancelOrderMutation',
    mutationFn: ({ id }: CancelOrderMutationOptions) =>
      axios
        .delete<SwitchOrderFull>(`${SWITCH_ORDERS_API_ROOT}/${id}`)
        .then(response => response.data)
        .catch((error: ErrorResponse | AxiosError) => {
          throw error;
        })
  });
};

interface CreateOrderMutationOptions {
  switchId: string;
  selectedSwitchOrderReference: string;
  plannedSwitchDate: string;
}

// GRCP Only
export const useCreateOrderMutation = () => {
  const axios = useAxiosWrapper();

  return useMutation<SwitchOrderFull, unknown, CreateOrderMutationOptions>({
    mutationKey: 'updateOrderMutation',
    mutationFn: ({ switchId, ...requestBody }: CreateOrderMutationOptions) =>
      axios
        .post<SwitchOrderFull>(`${SWITCH_ORDERS_API_ROOT}/${switchId}`, requestBody)
        .then(response => response.data)
        .catch((error: ErrorResponse | AxiosError) => {
          throw error;
        })
  });
};

interface OrderFailMutationOptions {
  id: string;
  faultCode: string;
  faultText: string;
}

export const useOrderFailMutation = () => {
  const axios = useAxiosWrapper();

  return useMutation<SwitchOrderFull, unknown, OrderFailMutationOptions>({
    mutationKey: 'orderFailMutation',
    mutationFn: ({ id, ...requestBody }: OrderFailMutationOptions) =>
      axios
        .patch<SwitchOrderFull>(`${SWITCH_ORDERS_API_ROOT}/${id}/order-fail`, requestBody)
        .then(response => response.data)
        .catch((error: ErrorResponse | AxiosError) => {
          throw error;
        })
  });
};

interface ConfirmOrderMutationOptions {
  id: string;
}

export const useConfirmOrderMutation = () => {
  const axios = useAxiosWrapper();

  return useMutation<SwitchOrderFull, unknown, ConfirmOrderMutationOptions>({
    mutationKey: 'ConfirmOrderMutation',
    mutationFn: ({ id, ...requestBody }: ConfirmOrderMutationOptions) =>
      axios
        .patch<SwitchOrderFull>(`${SWITCH_ORDERS_API_ROOT}/${id}/order-confirm`, {
          ...requestBody
        })
        .then(response => response.data)
        .catch((error: ErrorResponse | AxiosError) => {
          throw error;
        })
  });
};
