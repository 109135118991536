import { createTheme } from '@mui/material/styles';

// Theme configuration
export default createTheme({
  typography: {
    emptyValue: {
      fontSize: '1rem',
      fontStyle: 'italic',
      color: 'secondary'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          emptyValue: 'span'
        }
      }
    }
  },
  palette: {
    background: {
      default: '#F3F4F6'
    },

    primary: {
      main: '#2096F3',
      light: '#EDF7FE'
    },

    error: {
      main: '#D32F2F'
    },

    navbar: {
      main: '#3D506D' // Ocean 750
    },

    // switch order states
    MATCH_REQUESTED: {
      main: '#9747FF',
      light: '#9747FF',
      dark: '#9747FF',
      contrastText: '#FFFFFF'
    },
    MATCH_CONFIRMED: {
      main: '#009688',
      light: '#009688',
      dark: '#009688',
      contrastText: '#FFFFFF'
    },
    MATCH_FAILED: {
      main: '#D32F2F',
      light: '#D32F2F',
      dark: '#D32F2F',
      contrastText: '#FFFFFF'
    },
    MATCH_EXPIRED: {
      main: '#D32F2F',
      light: '#D32F2F',
      dark: '#D32F2F',
      contrastText: '#FFFFFF'
    },
    ORDER_REQUESTED: {
      main: '#9747FF',
      light: '#9747FF',
      dark: '#9747FF',
      contrastText: '#FFFFFF'
    },
    ORDER_FAILED: {
      main: '#D32F2F',
      light: '#D32F2F',
      dark: '#D32F2F',
      contrastText: '#FFFFFF'
    },
    ORDER_CONFIRMED: {
      main: '#2E7D32',
      light: '#2E7D32',
      dark: '#2E7D32',
      contrastText: '#FFFFFF'
    },
    ORDER_EXPIRED: {
      main: '#D32F2F',
      light: '#D32F2F',
      dark: '#D32F2F',
      contrastText: '#FFFFFF'
    },
    UPDATE_REQUESTED: {
      main: '#2196F3',
      light: '#2196F3',
      dark: '#2196F3',
      contrastText: '#FFFFFF'
    },
    UPDATE_FAILED: {
      main: '#D32F2F',
      light: '#D32F2F',
      dark: '#D32F2F',
      contrastText: '#FFFFFF'
    },
    ORDER_UPDATED: {
      main: '#2E7D32',
      light: '#2E7D32',
      dark: '#2E7D32',
      contrastText: '#FFFFFF'
    },
    TRIGGER_REQUESTED: {
      main: '#EF6C00',
      light: '#EF6C00',
      dark: '#EF6C00',
      contrastText: '#FFFFFF'
    },
    ORDER_COMPLETED: {
      main: '#1E4620',
      light: '#1E4620',
      dark: '#1E4620',
      contrastText: '#FFFFFF'
    },
    TRIGGER_FAILED: {
      main: '#D32F2F',
      light: '#D32F2F',
      dark: '#D32F2F',
      contrastText: '#FFFFFF'
    },
    CANCEL_REQUESTED: {
      main: '#EF6C00',
      light: '#EF6C00',
      dark: '#EF6C00',
      contrastText: '#FFFFFF'
    },
    ORDER_CANCELLED: {
      main: '#5F2120',
      light: '#5F2120',
      dark: '#5F2120',
      contrastText: '#FFFFFF'
    },
    CANCEL_FAILED: {
      main: '#D32F2F',
      light: '#D32F2F',
      dark: '#D32F2F',
      contrastText: '#FFFFFF'
    },
    HUB_DELIVERY_FAILURE: {
      main: '#D32F2F',
      light: '#D32F2F',
      dark: '#D32F2F',
      contrastText: '#FFFFFF'
    },

    // data import states
    SCHEDULED: {
      main: '#9747FF',
      light: '#9747FF',
      dark: '#9747FF',
      contrastText: '#FFFFFF'
    },
    STARTED: {
      main: '#EF6C00',
      light: '#EF6C00',
      dark: '#EF6C00',
      contrastText: '#FFFFFF'
    },
    FAILED: {
      main: '#D32F2F',
      light: '#D32F2F',
      dark: '#D32F2F',
      contrastText: '#FFFFFF'
    },
    COMPLETED: {
      main: '#1E4620',
      light: '#1E4620',
      dark: '#1E4620',
      contrastText: '#FFFFFF'
    }
  }
});

// Updates to theme modules/types to allow custom pieces
declare module '@mui/material/styles' {
  interface TypographyVariants {
    emptyValue: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    emptyValue?: React.CSSProperties;
  }
  interface Palette {
    navbar: Palette['primary'];

    // switch order states
    MATCH_REQUESTED: Palette['primary'];
    MATCH_CONFIRMED: Palette['primary'];
    MATCH_FAILED: Palette['primary'];
    MATCH_EXPIRED: Palette['primary'];
    ORDER_REQUESTED: Palette['primary'];
    ORDER_FAILED: Palette['primary'];
    ORDER_CONFIRMED: Palette['primary'];
    ORDER_EXPIRED: Palette['primary'];
    UPDATE_REQUESTED: Palette['primary'];
    UPDATE_FAILED: Palette['primary'];
    ORDER_UPDATED: Palette['primary'];
    TRIGGER_REQUESTED: Palette['primary'];
    ORDER_COMPLETED: Palette['primary'];
    TRIGGER_FAILED: Palette['primary'];
    CANCEL_REQUESTED: Palette['primary'];
    ORDER_CANCELLED: Palette['primary'];
    CANCEL_FAILED: Palette['primary'];
    HUB_DELIVERY_FAILURE: Palette['primary'];

    //data import states
    SCHEDULED?: Palette['primary'];
    FAILED?: Palette['primary'];
    COMPLETED?: Palette['primary'];
    STARTED?: Palette['primary'];
  }

  interface PaletteOptions {
    navbar?: PaletteOptions['primary'];
    // switch order states
    MATCH_REQUESTED?: PaletteOptions['primary'];
    MATCH_CONFIRMED?: PaletteOptions['primary'];
    MATCH_FAILED?: PaletteOptions['primary'];
    MATCH_EXPIRED?: PaletteOptions['primary'];
    ORDER_REQUESTED?: PaletteOptions['primary'];
    ORDER_FAILED?: PaletteOptions['primary'];
    ORDER_CONFIRMED?: PaletteOptions['primary'];
    ORDER_EXPIRED?: PaletteOptions['primary'];
    UPDATE_REQUESTED?: PaletteOptions['primary'];
    UPDATE_FAILED?: PaletteOptions['primary'];
    ORDER_UPDATED?: PaletteOptions['primary'];
    TRIGGER_REQUESTED?: PaletteOptions['primary'];
    ORDER_COMPLETED?: PaletteOptions['primary'];
    TRIGGER_FAILED?: PaletteOptions['primary'];
    CANCEL_REQUESTED?: PaletteOptions['primary'];
    ORDER_CANCELLED?: PaletteOptions['primary'];
    CANCEL_FAILED?: PaletteOptions['primary'];
    HUB_DELIVERY_FAILURE?: PaletteOptions['primary'];

    //data import states
    SCHEDULED?: PaletteOptions['primary'];
    FAILED?: PaletteOptions['primary'];
    COMPLETED?: PaletteOptions['primary'];
    STARTED?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    emptyValue: true;
  }
}
