import { FunctionComponent } from 'react';
import { Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { FilterValue } from 'app/types/filters';
import { formatFilterLabel } from 'app/utils/filters-utils';

const useStyles = makeStyles()(theme => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0,
    margin: theme.spacing(1, 0, 0)
  },
  item: {
    margin: theme.spacing(1, 1, 0, 0)
  }
}));

export interface FiltersProps {
  filters: FilterValue[];
  onRemoveFilter: (index: number, val: FilterValue) => void;
}

export const Filters: FunctionComponent<FiltersProps> = ({ filters, onRemoveFilter }) => {
  const { classes } = useStyles();

  return (
    <ul className={classes.list} data-testid={'filters-list'}>
      {filters.map((val, key) => (
        <li key={`filters-${key}`} className={classes.item}>
          <Chip
            label={formatFilterLabel(val)}
            variant='outlined'
            onClick={() => onRemoveFilter(key, val)}
            onDelete={() => onRemoveFilter(key, val)}
          />
        </li>
      ))}
    </ul>
  );
};
