import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';

import { LabelValue, Panel } from 'app/components';
import { READABLE_DATE_FORMAT } from 'app/constants/dates';
import { ServiceDetailsData } from 'app/types/serviceDetails';
import { SwitchOrderFull } from 'app/types/switchOrder';
import { formatDate } from 'app/utils/date-utils';

import { ServiceDetailGroup } from './ServiceDetailGroup';

export const ServicesPanel: FunctionComponent<{
  order: SwitchOrderFull;
  serviceDetails?: ServiceDetailsData[];
}> = ({ order, serviceDetails }) => {
  return (
    <Panel title='Services'>
      <Grid container spacing={0}>
        <Grid container item spacing={2}>
          <Grid item xs>
            {order.activationDate ? (
              <LabelValue label='Effective Trigger Date'>
                {formatDate(order.activationDate, READABLE_DATE_FORMAT)}
              </LabelValue>
            ) : (
              <LabelValue label='Planned Switch Date'>
                {formatDate(order.plannedSwitchDate, READABLE_DATE_FORMAT)}
              </LabelValue>
            )}
          </Grid>
          <Grid item xs>
            <LabelValue label='SOR'>{order.switchOrderReference}</LabelValue>
          </Grid>
        </Grid>

        {!!serviceDetails && <ServiceDetailGroup services={serviceDetails} />}
      </Grid>
    </Panel>
  );
};
