import { FunctionComponent, useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useField, useFormikContext } from 'formik';

import { useRcps } from 'app/hooks/useRcps';
import { RcpFullData } from 'app/types';
import { getProviderLabel } from 'app/utils/provider-utils';

type FormikAutocompleteProps = {
  name: string;
  label: string;
  helperText?: string;
  onChange?: (val: RcpFullData) => void;
};

export const FormikRcpAutocomplete: FunctionComponent<FormikAutocompleteProps> = ({
  name,
  label,
  helperText,
  onChange
}) => {
  // This autocomplete should only show "ACTIVE" OTS Providers (not including the user's provider)
  const { activeOTSProviders } = useRcps();
  const providersAsOptions = useMemo(
    () => activeOTSProviders.map(val => val.rcpId),
    [activeOTSProviders]
  );

  const { setFieldValue, handleBlur } = useFormikContext();
  const [field, meta] = useField(name);
  return (
    <Autocomplete
      disablePortal
      fullWidth
      autoHighlight
      value={field.value || null}
      options={providersAsOptions}
      onChange={(e, v: string) => {
        setFieldValue(field.name, v);
        onChange?.(activeOTSProviders.find(o => o.rcpId === v)!);
      }}
      onBlur={handleBlur}
      getOptionLabel={(option: string) => {
        const provider = activeOTSProviders.find(v => v.rcpId === option);
        return provider
          ? getProviderLabel({ rcpId: provider.rcpId, rcpName: provider.name })
          : option;
      }}
      disableClearable={true}
      onInputChange={() => {
        if (!!field.value && !providersAsOptions.includes(field.value)) {
          setFieldValue(field.name, '');
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            'data-testid': name,
            autoComplete: 'new-password' // disable autocomplete and autofill
          }}
          name={name}
          label={label}
          variant='standard'
          required={true}
          error={meta.touched && Boolean(meta.error)}
          helperText={
            <>
              {helperText} {meta.touched && meta.error && `${helperText ? '- ' : ''}${meta.error}`}
            </>
          }
        />
      )}
    />
  );
};
