import { FunctionComponent, useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import {
  CustomerDetailsPanel,
  NotFoundFiller,
  OrderDetailsPageHeader,
  PageLoadingIndicator,
  ProviderDetailsPanel
} from 'app/components';
import { NOTIFICATIONS, SWITCH_STATES, URLS } from 'app/constants';
import { useFetchOrder, useFetchOrderNotifications } from 'app/hooks/useOrderQueries';
import { MatchConfirmation } from 'app/types';

import { ServicesPanel } from './ServicesPanel';

const useStyles = makeStyles()(theme => ({
  row: {
    marginBottom: theme.spacing(2)
  },
  sameHeightRow: {
    display: 'flex',

    '& > * > *': {
      height: '100%'
    }
  },
  lastColumn: {
    width: '360px'
  }
}));

export const GenerateOrder: FunctionComponent = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();
  const params = useParams();

  const queryOptions = useMemo(
    () => ({
      switchId: params.id || ''
    }),
    [params.id]
  );

  const {
    isFetching: isOrderFetching,
    data: orderData,
    isError: isOrderError
  } = useFetchOrder(queryOptions);

  const { isFetching: isNotificationsFetching, data: notificationData } =
    useFetchOrderNotifications(
      {
        switchId: orderData?.switchId || ''
      },
      !isOrderFetching && !!orderData
    );

  const isFetching = useMemo(
    () => isOrderFetching || isNotificationsFetching,
    [isNotificationsFetching, isOrderFetching]
  );

  const switchMatchConfirmationData = useMemo(() => {
    const matchConfirmation = (notificationData || []).find(
      val => val.notificationType === NOTIFICATIONS.MatchConfirmation
    );
    return ((matchConfirmation?.content || {}) as MatchConfirmation).switchMatchConfirmationData;
  }, [notificationData]);

  useEffect(() => {
    if (orderData && orderData.state !== SWITCH_STATES.MATCH_CONFIRMED) {
      navigate(`/${URLS.ORDER_DETAILS.replace(':id', orderData.switchId)}`);
    }
  }, [navigate, orderData]);

  return (
    <div data-testid='generate-order'>
      <OrderDetailsPageHeader isFetching={isFetching} data={orderData} isGenerateSwitchOrder />
      {isFetching && <PageLoadingIndicator />}
      {isOrderError && (
        <NotFoundFiller message='The requested switch order could not be found. Please return to the list view.' />
      )}
      {!isFetching && !isOrderError && !!orderData && (
        <>
          <Grid container spacing={2} className={clsx(classes.row, classes.sameHeightRow)}>
            <Grid item xs>
              <CustomerDetailsPanel order={orderData} />
            </Grid>
            <Grid item xs>
              <ProviderDetailsPanel
                order={orderData}
                implicationsSent={switchMatchConfirmationData?.implicationsSent || []}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.row}>
            <Grid item xs>
              <ServicesPanel
                order={orderData}
                switchMatchDetails={switchMatchConfirmationData?.switchMatchDetails}
              />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
