export const ORDER_TABLE_ROW_HEIGHT = 72;
export const ORDER_TABLE_PAGE_SIZE = 25;
export const ORDER_TABLE_AUTO_REFRESH_DELAY = 90000; // 90 secs

export const ORDERS_TABLE_MIN_HEIGHT = ORDER_TABLE_ROW_HEIGHT * 3;

export const MATCH_REQUEST_DELAY_MESSAGE = 10; // 10 secs
export const MATCH_REQUEST_MAX_TIMEOUT = 90; // 90 secs

export const ORDER_TABLE_PAGE_SEARCH_PARAM = 'page';
export const ORDER_TABLE_PAGESIZE_SEARCH_PARAM = 'pageSize';
export const ORDER_TABLE_SORT_SEARCH_PARAM = 'sort';
export const ORDER_TABLE_SORTDIR_SEARCH_PARAM = 'sortDir';
export const ORDER_TABLE_PROVIDER_TYPE_SEARCH_PARAM = 'type';
export const ORDER_TABLE_FILTER_SEARCH_PARAM = 'filter';
