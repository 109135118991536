import { createContext, PropsWithChildren, useContext } from 'react';
import {
  AuthContextProps,
  AuthProvider as ReactOIDCProvider,
  useAuth as useReactOIDCAuth
} from 'react-oidc-context';

const MOCK_AUTH_VALUE = {
  isAuthenticated: true,
  isLoading: false,
  error: undefined,
  activeNavigator: undefined,

  user: {
    profile: {
      rcps: ['RLBR', 'RXVM'],
      email: 'email@example.com',
      preferred_username: 'Example'
    }
  },

  signinRedirect: () => {
    return;
  },
  signoutRedirect: () => {
    return;
  }
} as unknown as AuthContextProps;

type MockProviderType = PropsWithChildren & { value?: AuthContextProps };

const MockContext = createContext<AuthContextProps>({} as AuthContextProps);
const MockProvider: React.FC<MockProviderType> = ({ children, value }) => (
  <MockContext.Provider value={value ?? MOCK_AUTH_VALUE}>{children}</MockContext.Provider>
);

export const AuthProvider =
  process.env.NODE_ENV === 'test' || process.env.REACT_APP_DISABLE_KEYCLOAK === 'true'
    ? MockProvider
    : ReactOIDCProvider;

export const useAuth = () =>
  process.env.NODE_ENV === 'test' || process.env.REACT_APP_DISABLE_KEYCLOAK === 'true'
    ? useContext(MockContext)
    : useReactOIDCAuth();
