import { useEffect } from 'react';

type KeyActions = {
  [key: string]: () => void;
};
export const useKeyDown = (keyActions: KeyActions) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (Object.keys(keyActions).includes(event.key)) {
        event.preventDefault();
        event.stopPropagation();

        keyActions[event.key]();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [keyActions]);
};
