import { FunctionComponent, useState } from 'react';
import { Box, MenuItem, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';

import { FormikSelect, Modal, ModalActions, ModalContent, useModal } from 'app/components';
import { useOrderFailMutation } from 'app/hooks/useOrderQueries';
import theme from 'app/theme';
import { ErrorResponse, SwitchOrderFull } from 'app/types';
import { formatError } from 'app/utils/error-utils';

import { FAILURE_REASONS } from './constants';
import { validationSchema } from './validation';

interface FormikValuesProps {
  failureReason: keyof typeof FAILURE_REASONS;
}

export const FailOrder: FunctionComponent<{
  order: SwitchOrderFull;
  hasOpenOrderTypeCease?: boolean;
  hasOpenOrderTypeModify?: boolean;
}> = ({ order, hasOpenOrderTypeCease, hasOpenOrderTypeModify }) => {
  const { mutate } = useOrderFailMutation();
  const navigate = useNavigate();
  const { open, handleOpen, handleClose } = useModal();
  const [error, setError] = useState<ErrorResponse | undefined>();

  const handleSubmit = async ({ failureReason }: FormikValuesProps) => {
    setError(undefined);

    mutate(
      {
        id: order.switchId,
        faultCode: FAILURE_REASONS[failureReason].key,
        faultText: FAILURE_REASONS[failureReason].value
      },
      {
        onSuccess: () => {
          handleClose();
          navigate(0);
        },
        onError: err => {
          setError(formatError(err));
        }
      }
    );
  };

  return (
    <>
      <Button color='error' variant='outlined' onClick={handleOpen}>
        Fail Order
      </Button>

      <Modal
        modalTitle='Fail Order'
        modalError={error}
        isOpen={open}
        onClose={handleClose}
        width='720px'
      >
        <Formik
          enableReinitialize
          initialValues={{ failureReason: '' } as unknown as FormikValuesProps}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              <ModalContent>
                <Box mb={theme.spacing(3)}>
                  <FormikSelect
                    name='failureReason'
                    data-testid='failureReason'
                    label='Select failure reason'
                  >
                    {hasOpenOrderTypeCease && (
                      <MenuItem value='CEASE'>{FAILURE_REASONS.CEASE.value}</MenuItem>
                    )}
                    {hasOpenOrderTypeModify && (
                      <MenuItem value='MODIFY'>{FAILURE_REASONS.MODIFY.value}</MenuItem>
                    )}
                  </FormikSelect>
                </Box>
                <Typography variant='body1'>
                  You are confirming that this order request is being failed with the above reason.
                  <br />
                  This action cannot be reversed.
                </Typography>
              </ModalContent>

              <ModalActions>
                <Button onClick={handleClose}>Close</Button>
                <Button
                  color='error'
                  data-testid='fail-order'
                  variant='contained'
                  disabled={!isValid || isSubmitting}
                  type='submit'
                >
                  {isSubmitting ? <CircularProgress size={24} /> : 'Fail Order'}
                </Button>
              </ModalActions>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
