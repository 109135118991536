import { MouseEvent, useState } from 'react';
import { Box, Button, Divider, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { ListAlt } from '@mui/icons-material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { URLS } from 'app/constants';
import { AppFeature, hasFeatureEnabled } from 'app/features';
import { useAuth } from 'app/hooks/useAuth';
import { useIdentity } from 'app/hooks/useIdentity';
import { useRcps } from 'app/hooks/useRcps';
import theme from 'app/theme';
import { getProviderLabel } from 'app/utils/provider-utils';

const useStyles = makeStyles()(() => ({
  button: {
    textTransform: 'none',
    paddingRight: 0
  },
  menu: {
    marginTop: '45px'
  },
  menuItem: {
    '&:hover': { backgroundColor: 'transparent' }
  },
  menuIcon: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  }
}));

export const UserMenu = () => {
  const { classes } = useStyles();
  const auth = useAuth();

  const navigate = useNavigate();

  const { userProvider } = useRcps();
  const { rcpId, email, username } = useIdentity();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    auth.signoutRedirect();
  };

  return (
    <>
      <Button
        data-testid='user-menu-button'
        size='large'
        onClick={handleOpenUserMenu}
        endIcon={<AccountCircle />}
        className={classes.button}
        color='inherit'
      >
        {username || email}
      </Button>
      <Menu
        data-testid='user-menu'
        id='user-menu'
        className={classes.menu}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem className={classes.menuItem}>
          <ListItemText
            primary={username || email}
            secondary={getProviderLabel({ rcpName: userProvider?.name, rcpId })}
          />
        </MenuItem>

        <Divider />
        <MenuItem onClick={() => navigate(`/${URLS.ORDERS}`)}>
          <Box className={classes.menuIcon}>
            <ListAlt color='action' />
          </Box>
          <Typography>Orders</Typography>
        </MenuItem>
        {hasFeatureEnabled(AppFeature.DataImport) && (
          <MenuItem onClick={() => navigate(`/${URLS.DATA_IMPORT}`)}>
            <Box className={classes.menuIcon}>
              <SettingsIcon color='action' />
            </Box>
            <Typography>Data Import</Typography>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleLogout}>
          <Box className={classes.menuIcon}>
            <LogoutIcon color='action' />
          </Box>
          <Typography>Log out</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
