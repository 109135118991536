import { FunctionComponent } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import theme from 'app/theme';
import { ErrorResponse } from 'app/types';

export interface ErrorBannerProps {
  error: ErrorResponse;
}

export const ErrorBanner: FunctionComponent<ErrorBannerProps> = ({
  error: { errorCode, errorMessage, errorDetailedMessage }
}) => (
  <Alert variant='filled' severity='error' data-testid='error-banner'>
    <AlertTitle sx={{ marginBottom: errorDetailedMessage ? theme.spacing(1) : 0 }}>
      {errorCode}
      {errorMessage}
    </AlertTitle>
    {errorDetailedMessage}
  </Alert>
);
