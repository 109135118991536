import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot, { TimelineDotProps } from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';

export interface OrderTimelineProps {
  list: {
    id: string;
    date: string;
    color: TimelineDotProps['color'];
    text: string;
  }[];
}

export const OrderTimeline: FunctionComponent<OrderTimelineProps> = ({ list }) => {
  return (
    <Timeline
      sx={{
        margin: 0,
        padding: 0,
        [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0 }
      }}
    >
      {list.map(({ id, date, text, color }, index, { length }) => (
        <TimelineItem key={id}>
          <TimelineSeparator>
            <TimelineDot data-testid='timelineDot' color={index === 0 ? color : 'grey'} />
            {index + 1 !== length && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography color={'grey'} variant='subtitle2'>
              {date}
            </Typography>
            <Typography>{text}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};
