import { FunctionComponent, PropsWithChildren } from 'react';
import TextField from '@mui/material/TextField';
import { useField, useFormikContext } from 'formik';

type FormikSelectProps = PropsWithChildren & {
  name: string;
  label: string;
  helperText?: string;
  required?: boolean;
  onChange?: (val: string) => void;
};

export const FormikSelect: FunctionComponent<FormikSelectProps> = ({
  name,
  label,
  helperText,
  required = false,
  onChange,
  children
}) => {
  const { handleChange, handleBlur } = useFormikContext();
  const [field, meta] = useField(name);
  return (
    <TextField
      select
      name={name}
      label={label}
      required={required}
      fullWidth
      variant='standard'
      value={field.value}
      inputProps={{ 'data-testid': name }}
      onChange={e => {
        handleChange(e);
        onChange?.(e.target.value);
      }}
      onBlur={handleBlur}
      error={meta.touched && Boolean(meta.error)}
      helperText={
        <>
          {helperText} {meta.touched && meta.error && `${helperText ? '- ' : ''}${meta.error}`}
        </>
      }
    >
      {children}
    </TextField>
  );
};
