import { FunctionComponent, useEffect } from 'react';
import { matchPath, Navigate, Route, Routes as ReactRoutes, useLocation } from 'react-router-dom';

import { NotFoundPage } from 'app/components';
import { URLS } from 'app/constants/routes';
import { AppFeature, hasFeatureEnabled } from 'app/features';

import { CreateMatchRequest } from './CreateMatchRequest';
import { DataImport } from './DataImport';
import { GenerateOrder } from './GenerateOrder';
import { OrderDetails } from './OrderDetails';
import { Orders } from './Orders';

const defaultDocumentTitle = 'pivOTS';

const ROUTES = [
  { path: URLS.ORDERS, element: <Orders />, title: 'pivOTS - Switch Order List', enabled: true },
  {
    path: URLS.ORDER_DETAILS,
    element: <OrderDetails />,
    title: 'pivOTS - Switch Order Details',
    enabled: true
  },
  {
    path: URLS.NEW_MATCH_REQUEST,
    element: <CreateMatchRequest />,
    title: 'pivOTS - Create Match Request',
    enabled: true
  },
  {
    path: URLS.AMEND_MATCH_REQUEST,
    element: <CreateMatchRequest />,
    title: 'pivOTS - Amend Match Request',
    enabled: true
  },
  {
    path: URLS.GENERATE_ORDER,
    element: <GenerateOrder />,
    title: 'pivOTS - Generate Switch Order',
    enabled: true
  },
  {
    path: URLS.DATA_IMPORT,
    element: <DataImport />,
    title: 'pivOTS - Data Import',
    enabled: hasFeatureEnabled(AppFeature.DataImport)
  }
];

const Routes: FunctionComponent = () => {
  const location = useLocation();

  useEffect(() => {
    const currentRoute = ROUTES.find(route => matchPath(route.path, location.pathname));

    document.title = currentRoute ? currentRoute.title : defaultDocumentTitle;
  }, [location]);

  return (
    <ReactRoutes>
      <Route path='/'>
        {ROUTES.filter(route => route.enabled).map(route => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
        <Route index element={<Navigate to={URLS.ORDERS} replace={true} />} />
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </ReactRoutes>
  );
};

export default Routes;
