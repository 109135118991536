import { FunctionComponent } from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from 'tss-react/mui';

import { DATA_IMPORT_STATES } from 'app/constants';
import { DATA_IMPORT_STATES_TYPE } from 'app/types/typesFromEnums';
import { getDataImportStateValueLabel } from 'app/utils/imports-utils';

const useStyles = makeStyles()(() => ({
  state: {
    fontSize: '16px',
    lineHeight: 1.5
  }
}));

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    SCHEDULED: true;
    STARTED: true;
    FAILED: true;
    COMPLETED: true;
  }
}

export const DataImportState: FunctionComponent<{ state: DATA_IMPORT_STATES_TYPE }> = ({
  state
}) => {
  const { classes } = useStyles();

  return (
    <Chip
      className={classes.state}
      color={state in DATA_IMPORT_STATES ? state : 'default'}
      label={getDataImportStateValueLabel(state)}
      data-testid='dataImportState'
    />
  );
};
