export enum NOTIFICATIONS {
  MatchConfirmation = 'matchConfirmation',
  SwitchRequestFailure = 'matchFailure',
  SwitchingImplications = 'switchingImplications',
  OrderRequest = 'orderRequest',
  OrderFailure = 'orderFailure',
  // OrderUpdateRequest = 'orderUpdateRequest', - Not Supported
  OrderUpdateFailure = 'orderUpdateFailure',
  OrderTriggerFailure = 'orderTriggerFailure',
  // OrderCancelRequest = 'orderCancelRequest', - Not Supported
  OrderCancelFailure = 'orderCancelFailure',
  OrderConfirmation = 'orderConfirmation',
  OrderUpdateConfirmation = 'orderUpdateConfirmation',
  OrderTriggerRequest = 'orderTriggerRequest',
  OrderTriggerConfirmation = 'orderTriggerConfirmation',
  OrderCancelConfirmation = 'orderCancelConfirmation',
  OrderExpiration = 'orderExpiration',
  HubMessageProcessingFailure = 'hubMessageProcessingFailure',
  HubMessageDeliveryFailure = 'hubMessageDeliveryFailure'
}
