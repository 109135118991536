import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { makeStyles } from 'tss-react/mui';

import { DateRangePicker } from 'app/components';
import { DATA_IMPORT_STATES_AS_OPTIONS } from 'app/constants';
import { FilterValue } from 'app/types/filters';
import { DATA_IMPORT_STATES_TYPE } from 'app/types/typesFromEnums';
import { formatDate, StringDateDayjs } from 'app/utils/date-utils';
import { getDataImportStateValueLabel } from 'app/utils/imports-utils';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  columnFilter: {
    margin: theme.spacing(0, 1, 0, 0),
    minWidth: 210,
    flex: '3 1'
  },
  valueFilter: {
    margin: theme.spacing(0, 1),
    minWidth: 200,
    flex: '3 1'
  },
  button: {
    height: 40,
    margin: theme.spacing(0, 1)
  }
}));

export interface FilterControlsProps {
  filters: FilterValue[];
  onFilterChange: (val: FilterValue[]) => void;
}

export const FilterControls: FunctionComponent<FilterControlsProps> = ({
  filters,
  onFilterChange
}) => {
  const { classes } = useStyles();
  const [stateFilter, setStateFilter] = useState<string[]>([]);
  const [dateRangeFilter, setDateRangeFilter] = useState<{
    startDate: StringDateDayjs | null;
    endDate: StringDateDayjs | null;
  }>({
    startDate: null,
    endDate: null
  });

  useEffect(() => {
    const statusFilter = filters.find(filter => filter.field === 'status')?.value.split(',') || [];
    const fromDate = filters.find(filter => filter.field === 'fromDate')?.value || null;
    const toDate = filters.find(filter => filter.field === 'toDate')?.value || null;

    setStateFilter(statusFilter);
    setDateRangeFilter({ startDate: fromDate, endDate: toDate });
  }, [filters]);

  const handleAddFilter = () => {
    const newFilters: FilterValue[] = [];

    if (stateFilter.length > 0) {
      newFilters.push({ field: 'status', value: stateFilter.join(',') });
    }
    if (dateRangeFilter.startDate) {
      newFilters.push({ field: 'fromDate', value: formatDate(dateRangeFilter.startDate) });
    }
    if (dateRangeFilter.endDate) {
      newFilters.push({ field: 'toDate', value: formatDate(dateRangeFilter.endDate) });
    }

    onFilterChange(newFilters);
  };

  const handleStateFilterChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    setStateFilter(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div className={classes.container} data-testid='filter-controls'>
      <FormControl className={classes.columnFilter} data-testid='filter-control-field'>
        <InputLabel id='state-filter'>State</InputLabel>
        <Select
          labelId='state-filter'
          id='field'
          multiple
          input={<OutlinedInput label='State' />}
          inputProps={{ 'data-testid': 'filter-control-field-input' }}
          value={stateFilter}
          sx={{ width: 300 }}
          renderValue={selected =>
            selected
              .map(state => getDataImportStateValueLabel(state as DATA_IMPORT_STATES_TYPE))
              .join(', ')
          }
          onChange={handleStateFilterChange}
        >
          {DATA_IMPORT_STATES_AS_OPTIONS.map((option, key) => (
            <MenuItem key={`state-${key}`} value={option.value}>
              <Checkbox checked={stateFilter.indexOf(option.value) > -1} />
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.valueFilter} data-testid='filter-control-value'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker dateRange={dateRangeFilter} onChange={setDateRangeFilter} />
        </LocalizationProvider>
      </FormControl>
      <Button
        size='medium'
        variant='contained'
        data-testid='filter-control-button'
        className={classes.button}
        onClick={handleAddFilter}
      >
        Apply Filters
      </Button>
    </div>
  );
};
