import { useQuery } from 'react-query';

import { RCPS_API_ROOT } from 'app/constants/routes';
import { PaginatedResponse, RcpFullData } from 'app/types';

import { useAxiosWrapper } from './useAxiosWrapper';

export const useFetchRcps = (enabled = false) => {
  const axios = useAxiosWrapper();
  return useQuery({
    queryKey: ['providers'],
    queryFn: () =>
      axios
        .get<PaginatedResponse<RcpFullData>>(RCPS_API_ROOT, {
          params: { size: 5000 }
        })
        .then(response => response.data),
    enabled
  });
};
