import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ErrorBanner, OrderTimeline, Panel } from 'app/components';
import { SwitchOrderNotification } from 'app/types/notifications';
import { formatError } from 'app/utils/error-utils';
import { prepareTimelineData } from 'app/utils/order-timeline-utils';

const useStyles = makeStyles()(theme => ({
  noHistory: {
    fontStyle: 'italic',
    color: theme.palette.text.secondary
  }
}));

export const OrderHistoryPanel: FunctionComponent<{
  isError: boolean;
  notifications?: SwitchOrderNotification[];
}> = ({ isError, notifications = [] }) => {
  const { classes } = useStyles();

  const renderContent = () => {
    if (isError) {
      return (
        <ErrorBanner
          error={formatError({
            errorDetailedMessage: 'Unable to load data due to a failed request.'
          })}
        />
      );
    }

    if (notifications.length > 0) {
      return <OrderTimeline {...prepareTimelineData(notifications)} />;
    }

    return <Typography className={classes.noHistory}>No history available</Typography>;
  };

  return <Panel title='Order History'>{renderContent()}</Panel>;
};
