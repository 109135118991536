import { FunctionComponent, MouseEvent } from 'react';
import { FormControl, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { makeStyles } from 'tss-react/mui';

import { ServiceActionOption } from 'app/components/Orders';
import { SERVICE_TYPES, SWITCH_ACTION_OPTIONS } from 'app/constants/switchProcess';
import { SERVICE_TYPES_TYPE } from 'app/types/typesFromEnums';

const useStyles = makeStyles()(theme => ({
  container: {
    margin: theme.spacing(0.5, 0)
  },
  button: {
    padding: theme.spacing(1, 2, 1, 1.5)
  }
}));

interface ServiceActionButtonGroupProps {
  name: string;
  type: SERVICE_TYPES_TYPE;
}

export const ServiceActionButtonGroup: FunctionComponent<ServiceActionButtonGroupProps> = ({
  name,
  type
}) => {
  const { classes } = useStyles();
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const handleChange = (e: MouseEvent<HTMLElement>, value: string | null) => {
    if (value !== null) {
      setFieldValue(field.name, value);
    }
  };

  return (
    <FormControl>
      <ToggleButtonGroup
        className={classes.container}
        value={field.value}
        exclusive
        size='small'
        onChange={handleChange}
      >
        <ToggleButton
          value={SWITCH_ACTION_OPTIONS.CEASE}
          className={classes.button}
          data-testid='option-cease'
        >
          <ServiceActionOption action={SWITCH_ACTION_OPTIONS.CEASE} />
        </ToggleButton>
        {type !== SERVICE_TYPES.BROADBAND && (
          <ToggleButton
            value={SWITCH_ACTION_OPTIONS.PORT}
            className={classes.button}
            data-testid='option-port'
          >
            <ServiceActionOption action={SWITCH_ACTION_OPTIONS.PORT} />
          </ToggleButton>
        )}
        {type !== SERVICE_TYPES.BROADBAND && (
          <ToggleButton
            value={SWITCH_ACTION_OPTIONS.IDENTIFY}
            className={classes.button}
            data-testid='option-identify'
          >
            <ServiceActionOption action={SWITCH_ACTION_OPTIONS.IDENTIFY} />
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </FormControl>
  );
};
