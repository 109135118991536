import dayjs from 'dayjs';
import * as yup from 'yup';

export const getValidationTriggerSwitch = (minDate: string, maxDate: string) =>
  yup.object({
    activationDate: yup
      .date()
      .typeError('Invalid date')
      .required('Effective Trigger Date is required')
      .min(
        dayjs(minDate).subtract(1, 'day'),
        'Effective Trigger Date must be between today or the created date'
      )
      .max(maxDate, 'Effective Trigger Date must be between today or the created date')
  });
