import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { IconButton, InputAdornment, Popover, Stack, TextField } from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs, { Dayjs } from 'dayjs';

import { FILTER_DATE_FORMAT } from 'app/constants';
import { formatDate, StringDateDayjs } from 'app/utils/date-utils';

import { DateFieldMaskInput } from './DateFieldMaskInput';

export interface DateRangePickerProps {
  dateRange: { startDate: StringDateDayjs | null; endDate: StringDateDayjs | null };
  onChange: (range: { startDate: StringDateDayjs; endDate: StringDateDayjs }) => void;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  dateRange,
  onChange,
  ...rest
}) => {
  const [startDate, setStartDate] = useState<StringDateDayjs | null>();
  const [endDate, setEndDate] = useState<StringDateDayjs | null>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [inputValue, setInputValue] = useState<string>('');

  const memoStartDateForSecondCalendar = useMemo(
    () => (startDate ? dayjs(startDate).add(1, 'month') : dayjs().add(1, 'month')),
    [startDate]
  );

  useEffect(() => {
    setStartDate(dateRange.startDate);
    setEndDate(dateRange.endDate);
  }, [dateRange.endDate, dateRange.startDate]);

  const memoDateRange = useMemo(() => {
    if (startDate) {
      return `${formatDate(startDate, FILTER_DATE_FORMAT)} – ${
        endDate ? formatDate(endDate, FILTER_DATE_FORMAT) : ''
      }`;
    }
    return '';
  }, [startDate, endDate]);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'datepicker' : undefined;

  const handleDatePick = (date: Dayjs | null) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date as StringDateDayjs);
      setEndDate(null);
    } else if (startDate && !endDate && date?.isAfter(startDate)) {
      setEndDate(date as StringDateDayjs);
      onChange({ startDate: formatDate(startDate), endDate: formatDate(date) });
    } else {
      setStartDate(date as StringDateDayjs);
      setEndDate(null);
    }
    setInputValue('');
  };

  const getParsedDateFromRange = (range: string) => {
    const [start, end] = range.split(' – ');

    return [dayjs(start, FILTER_DATE_FORMAT), dayjs(end, FILTER_DATE_FORMAT)];
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setInputValue(value);

    if (!value) {
      setStartDate(null);
      setEndDate(null);
      onChange({ startDate: '', endDate: '' });
      return;
    }

    const [parsedStartDate, parsedEndDate] = getParsedDateFromRange(value);

    if (parsedStartDate.isValid()) {
      setStartDate(parsedStartDate);
      onChange({ startDate: formatDate(parsedStartDate), endDate: '' });
    } else {
      setStartDate(null);
      onChange({ startDate: '', endDate: '' });
    }

    if (parsedEndDate.isValid() && parsedEndDate.isAfter(parsedStartDate)) {
      setEndDate(parsedEndDate);
      onChange({ startDate: formatDate(parsedStartDate), endDate: formatDate(parsedEndDate) });
    } else {
      setEndDate(null);
      onChange({ startDate: formatDate(parsedStartDate), endDate: '' });
    }
  };

  const handleBlur = () => {
    if (inputValue) {
      const [parsedStartDate, parsedEndDate] = getParsedDateFromRange(inputValue);

      if (
        !parsedStartDate.isValid() ||
        !parsedEndDate.isValid() ||
        !parsedEndDate.isAfter(parsedStartDate)
      ) {
        setInputValue('');
      }
    }
  };

  const highlightPickersDay = (props: PickersDayProps<Dayjs>) => {
    const isStart = startDate && props.day.isSame(startDate, 'day');
    const isEnd = endDate && props.day.isSame(endDate, 'day');
    const isInRange =
      startDate && endDate && props.day.isAfter(startDate) && props.day.isBefore(endDate);

    return <PickersDay {...props} selected={Boolean(isStart || isEnd || isInRange)} />;
  };

  return (
    <>
      <TextField
        label='Date Range'
        value={inputValue || memoDateRange}
        onBlur={handleBlur}
        onChange={handleInputChange}
        variant='outlined'
        placeholder={`${FILTER_DATE_FORMAT} – ${FILTER_DATE_FORMAT}`}
        sx={{ width: 300 }}
        InputProps={{
          inputComponent: DateFieldMaskInput as never,
          endAdornment: (
            <InputAdornment position='start'>
              <IconButton
                data-testid='calendar-button'
                aria-describedby={id}
                onClick={handleOpen}
                edge='end'
              >
                <CalendarIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        id={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Stack direction='row' spacing={1}>
          <StaticDatePicker
            value={dayjs(startDate)}
            onChange={handleDatePick}
            slotProps={{
              actionBar: {
                actions: []
              }
            }}
            slots={{
              toolbar: undefined,
              day: highlightPickersDay
            }}
            {...rest}
          />
          <StaticDatePicker
            value={memoStartDateForSecondCalendar}
            onChange={handleDatePick}
            slotProps={{
              actionBar: {
                actions: []
              }
            }}
            slots={{
              toolbar: undefined,
              day: highlightPickersDay
            }}
            {...rest}
          />
        </Stack>
      </Popover>
    </>
  );
};
