import { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { makeStyles } from 'tss-react/mui';

import { LabelValue, Panel } from 'app/components';
import { ServicesData } from 'app/types/serviceDetails';

import { ServiceDetailGroup } from './ServiceDetailGroup';

const useStyles = makeStyles()(theme => ({
  content: {
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: 4,
    padding: theme.spacing(2)
  }
}));

export const ServicesLosingPanel: FunctionComponent<{
  title: string;
  servicesData: ServicesData[];
}> = ({ title, servicesData }) => {
  const { classes } = useStyles();

  if (servicesData.length === 0) {
    return null;
  }

  return (
    <Panel title={title}>
      <Stack spacing={2}>
        {servicesData.map(({ switchOrderReference, details, isSelected }, index) => (
          <Box className={classes.content} key={`ServiceDetailGroup_${index}`}>
            <LabelValue
              label={isSelected ? 'Ordered SOR' : 'SOR'}
              valueColor={isSelected ? 'primary' : undefined}
            >
              {switchOrderReference}
            </LabelValue>
            {!!details && <ServiceDetailGroup services={details} />}
          </Box>
        ))}
      </Stack>
    </Panel>
  );
};
