import { FilterDefinition } from 'app/types/filters';
import { getStateValueLabel } from 'app/utils/order-utils';

import { FILTER_TYPES } from './filters';
import { SWITCH_STATES } from './switchProcess';

const SORTED_STATES = [
  SWITCH_STATES.MATCH_REQUESTED,
  SWITCH_STATES.MATCH_CONFIRMED,
  SWITCH_STATES.MATCH_FAILED,
  SWITCH_STATES.MATCH_EXPIRED,

  SWITCH_STATES.ORDER_REQUESTED,
  SWITCH_STATES.ORDER_CONFIRMED,
  SWITCH_STATES.ORDER_FAILED,
  SWITCH_STATES.ORDER_EXPIRED,
  SWITCH_STATES.ORDER_UPDATED,
  SWITCH_STATES.ORDER_CANCELLED,
  SWITCH_STATES.ORDER_COMPLETED,

  SWITCH_STATES.UPDATE_REQUESTED,
  SWITCH_STATES.UPDATE_FAILED,

  SWITCH_STATES.TRIGGER_REQUESTED,
  SWITCH_STATES.TRIGGER_FAILED,

  SWITCH_STATES.CANCEL_REQUESTED,
  SWITCH_STATES.CANCEL_FAILED,

  SWITCH_STATES.HUB_DELIVERY_FAILURE
];

const STATES_AS_OPTIONS = SORTED_STATES.map(state => ({
  label: getStateValueLabel(state),
  value: state
}));

export const ORDER_FILTERS_ALLOWED_MULTIPLES = ['state', 'rcpId', 'lrcpId', 'grcpId', 'notInState'];

export const ORDER_FILTER_DEFS: FilterDefinition[] = [
  {
    field: 'uiReference',
    fieldName: 'UI Reference',
    type: FILTER_TYPES.STRING
  },
  {
    field: 'accountRef',
    fieldName: 'Account Ref',
    type: FILTER_TYPES.STRING
  },
  {
    field: 'surname',
    fieldName: 'Surname',
    type: FILTER_TYPES.STRING
  },
  {
    field: 'postcode',
    fieldName: 'Postcode',
    type: FILTER_TYPES.STRING
  },
  {
    field: 'sor',
    fieldName: 'SOR',
    type: FILTER_TYPES.STRING
  },
  {
    field: 'state',
    fieldName: 'State',
    type: FILTER_TYPES.SELECT,
    options: STATES_AS_OPTIONS
  },
  {
    field: 'notInState',
    fieldName: 'Not With State',
    type: FILTER_TYPES.SELECT,
    options: STATES_AS_OPTIONS
  },
  {
    field: 'rcpId',
    fieldName: 'RCP',
    type: FILTER_TYPES.SELECT
  },
  {
    field: 'effectiveDate',
    fieldName: 'Effective Date',
    type: FILTER_TYPES.DATE
  },
  {
    field: 'lastUpdatedDate',
    fieldName: 'Last Updated',
    type: FILTER_TYPES.DATE
  }
];
