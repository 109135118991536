import { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import { PROVIDER_ROLES, SWITCH_ACTION_OPTIONS } from 'app/constants/switchProcess';
import { PROVIDER_ROLES_TYPE, SWITCH_ACTION_OPTIONS_TYPE } from 'app/types/typesFromEnums';
import ceaseIcon from 'assets/service-icons/cease.svg';
import identifyIcon from 'assets/service-icons/identify.svg';
import notFoundIcon from 'assets/service-icons/not-found.svg';
import portGainingIcon from 'assets/service-icons/port-gaining.svg';
import portLosingIcon from 'assets/service-icons/port-losing.svg';

export interface ServiceActionProps {
  action: SWITCH_ACTION_OPTIONS_TYPE;
  role?: PROVIDER_ROLES_TYPE;
  className?: string | undefined;
}

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(0.5)
  }
}));

const getSwitchActionOptionIcon = (
  option: SWITCH_ACTION_OPTIONS_TYPE,
  role?: PROVIDER_ROLES_TYPE
) => {
  switch (option) {
    case SWITCH_ACTION_OPTIONS.CEASE:
      return ceaseIcon;
    case SWITCH_ACTION_OPTIONS.IDENTIFY:
      return identifyIcon;
    case SWITCH_ACTION_OPTIONS.PORT:
      return role === PROVIDER_ROLES.LOSING ? portLosingIcon : portGainingIcon;
    default:
      return notFoundIcon;
  }
};

export const getSwitchActionOptionLabel = (action?: SWITCH_ACTION_OPTIONS_TYPE) => {
  switch (action) {
    case SWITCH_ACTION_OPTIONS.CEASE:
      return 'Cease';
    case SWITCH_ACTION_OPTIONS.PORT:
      return 'Port';
    case SWITCH_ACTION_OPTIONS.IDENTIFY:
      return 'Identify';
    default:
      return action;
  }
};

export const ServiceActionOption: FunctionComponent<ServiceActionProps> = ({
  action,
  role,
  className
}) => {
  const { classes } = useStyles();

  return (
    <div className={clsx(classes.container, className)} data-testid='serviceActionOption'>
      <img
        src={getSwitchActionOptionIcon(action, role)}
        width='20px'
        height='20px'
        className={classes.icon}
      />
      <Typography variant='body1'>{getSwitchActionOptionLabel(action)}</Typography>
    </div>
  );
};
