import { ChangeEvent, FunctionComponent, SyntheticEvent, useMemo, useState } from 'react';
import { Button, FormControl, MenuItem, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { makeStyles } from 'tss-react/mui';

import { STANDARD_DATE_FORMAT } from 'app/constants/dates';
import { FILTER_TYPES } from 'app/constants/filters';
import { ORDER_FILTER_DEFS } from 'app/constants/orderFilters';
import { useKeyDown } from 'app/hooks/useKeyDown';
import { useRcps } from 'app/hooks/useRcps';
import { AutoCompleteFilterValue, FilterDefinition, FilterValue } from 'app/types/filters';
import { getProviderLabel } from 'app/utils/provider-utils';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  columnFilter: {
    margin: theme.spacing(0, 1, 0, 0),
    minWidth: 210,
    flex: '2 1'
  },
  valueFilter: {
    margin: theme.spacing(0, 1),
    minWidth: 200,
    flex: '3 1'
  },
  button: {
    height: 40,
    margin: theme.spacing(0, 1)
  }
}));

export interface FilterControlsProps {
  onFilterChange: (val: FilterValue) => void;
}

export const FilterControls: FunctionComponent<FilterControlsProps> = ({ onFilterChange }) => {
  const { classes } = useStyles();
  const { providers } = useRcps();

  const providerOptions = useMemo(
    () =>
      providers.map(val => ({
        label: getProviderLabel({ rcpId: val.rcpId, rcpName: val.name }),
        value: val.rcpId
      })),
    [providers]
  );

  const [selectedFilterDef, setSelectedFilterDef] = useState<FilterDefinition | undefined>(
    ORDER_FILTER_DEFS[0]
  );
  const [filterField, setFilterField] = useState<string>(ORDER_FILTER_DEFS[0].field);
  const [filterValue, setFilterValue] = useState<string>('');
  const [filterDateValue, setFilterDateValue] = useState<dayjs.Dayjs>(dayjs());
  const [filterSelectValue, setFilterSelectValue] = useState<AutoCompleteFilterValue>({
    label: '',
    value: ''
  });

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterField(event.target.value);
    setSelectedFilterDef(ORDER_FILTER_DEFS.find(({ field }) => field === event.target.value));
    // reset the input values
    setFilterValue('');
    setFilterDateValue(dayjs());
    setFilterSelectValue({ label: '', value: '' });
  };
  const handleFilterValueChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterValue(event.target.value);
  };
  const handleFilterSelectValueChange = (
    e: SyntheticEvent<Element, Event>,
    v: AutoCompleteFilterValue
  ) => {
    setFilterSelectValue(v);
  };

  const handleAddFilter = () => {
    // clearing spaces
    let value = filterValue.trim().replace(/\s\s+/g, ' ');
    if (selectedFilterDef?.type === FILTER_TYPES.DATE) {
      value = filterDateValue?.format(STANDARD_DATE_FORMAT) || '';
    } else if (selectedFilterDef?.type === FILTER_TYPES.SELECT) {
      value = filterSelectValue?.value || '';
    }

    if (value) {
      onFilterChange({
        field: filterField,
        value
      });
      // reset the input values
      setFilterValue('');
      setFilterSelectValue({ label: '', value: '' });
    }
  };

  const isAddFilterEnabled = useMemo(() => {
    if (!selectedFilterDef) return;

    let hasValue = filterValue !== undefined && filterValue !== '' && filterValue.trim() !== '';
    if (selectedFilterDef.type === FILTER_TYPES.DATE) {
      hasValue = !!filterDateValue;
    } else if (selectedFilterDef.type === FILTER_TYPES.SELECT) {
      hasValue = filterSelectValue?.value !== '';
    }

    return !!filterField && hasValue;
  }, [filterField, filterDateValue, filterSelectValue, filterValue, selectedFilterDef]);

  const selectOptions = useMemo(
    () =>
      selectedFilterDef?.options
        ? selectedFilterDef?.options
        : selectedFilterDef?.field === 'rcpId'
        ? providerOptions
        : [],
    [selectedFilterDef, providerOptions]
  );

  useKeyDown({
    Enter: () => isAddFilterEnabled && handleAddFilter()
  });

  const renderFilterValueInput = () => {
    switch (selectedFilterDef?.type) {
      case FILTER_TYPES.DATE:
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='On or After'
              value={filterDateValue}
              onChange={newValue => {
                setFilterDateValue(newValue || dayjs());
              }}
              format={STANDARD_DATE_FORMAT}
            />
          </LocalizationProvider>
        );
      case FILTER_TYPES.SELECT:
        return (
          <Autocomplete
            disablePortal
            autoHighlight
            value={filterSelectValue}
            options={selectOptions}
            onChange={handleFilterSelectValueChange}
            disableClearable={true}
            renderInput={params => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': 'filter-control-value-select',
                  autoComplete: 'new-password' // disable autocomplete and autofill
                }}
                id='filterSelectValue'
                label='Value'
              />
            )}
          />
        );
      default:
        return (
          <TextField
            id='filterValue'
            label='Value'
            inputProps={{ 'data-testid': 'filter-control-value-input' }}
            value={filterValue}
            onChange={handleFilterValueChange}
          />
        );
    }
  };

  return (
    <div className={classes.container} data-testid='filter-controls'>
      <FormControl className={classes.columnFilter} data-testid='filter-control-field'>
        <TextField
          id='field'
          select
          label='Filter By'
          inputProps={{ 'data-testid': 'filter-control-field-input' }}
          value={filterField}
          onChange={handleFieldChange}
        >
          {ORDER_FILTER_DEFS.map((option, key) => (
            <MenuItem key={`filterBy-${key}`} value={option.field}>
              {option.fieldName}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
      <FormControl className={classes.valueFilter} data-testid='filter-control-value'>
        {renderFilterValueInput()}
      </FormControl>
      <Button
        size='medium'
        variant='contained'
        data-testid='filter-control-button'
        disabled={!isAddFilterEnabled}
        className={classes.button}
        onClick={handleAddFilter}
      >
        Add Filter
      </Button>
    </div>
  );
};
