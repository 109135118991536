import { FILTER_TYPES } from 'app/constants/filters';
import { ORDER_FILTER_DEFS, ORDER_FILTERS_ALLOWED_MULTIPLES } from 'app/constants/orderFilters';
import { ORDER_TABLE_FILTER_SEARCH_PARAM } from 'app/constants/orders';
import { FilterValue } from 'app/types/filters';

const DELIMITER = '|';

export const formatFilterLabel = ({ field, value }: FilterValue) => {
  // if the new filter is for a SELECT field, then just add it - SELECTs are allowed multiples
  const filterDef = ORDER_FILTER_DEFS.find(val => val.field === field);

  if (filterDef && filterDef.type === FILTER_TYPES.DATE) {
    return `${filterDef.fieldName} on or after ${value}`;
  } else {
    return `${filterDef?.fieldName || field} = "${value}"`;
  }
};

// Adds the "newFilter" to the array of "filters" - but first it checks if this is a duplicate value.
// If it's a duplicate (and not a SELECT type), then newFilter will replace the existing filter.
export const sanitiseFilterList = (filters: FilterValue[], newFilter: FilterValue) => {
  // if the new filter is for a SELECT field, then just add it - SELECTs are allowed multiples
  const filterDef = ORDER_FILTER_DEFS.find(val => val.field === newFilter.field);

  if (filterDef && filterDef.type === FILTER_TYPES.SELECT) {
    const isDuplicate = filters.some(val => JSON.stringify(val) === JSON.stringify(newFilter));

    if (isDuplicate) {
      return filters;
    }

    return [...filters, newFilter];
  }

  // otherwise, if the non-SELECT filter already exists, then just update the value
  const field = filters.find(val => val.field === newFilter.field);
  if (field) {
    field.value = newFilter.value;
    return filters;
  }

  return [...filters, newFilter];
};

const convertFilterValueToString = ({ field, value }: FilterValue) =>
  `${field}${DELIMITER}${value}`;

export const convertStringToFilterValue = (filterAsString: string): FilterValue | undefined => {
  const parts = filterAsString.split(DELIMITER);
  // there will always be 2 parts to filter - the field and the value
  if (parts.length < 2) return;
  return {
    field: parts[0],
    value: parts.slice(1).join(DELIMITER) // this allows for the DELIMITER value to be used as part of the value
  } as FilterValue;
};

export const buildSearchParamsFromFilterValues = (
  filters: FilterValue[],
  urlParams = new URLSearchParams(),
  paramName = ORDER_TABLE_FILTER_SEARCH_PARAM
) => {
  // remove any previous "filter" params before adding
  urlParams.delete(paramName);
  filters.forEach(filter => {
    urlParams.append(paramName, convertFilterValueToString(filter));
  });

  return urlParams;
};

export const convertFilterValuesForAPI = (filters: FilterValue[]) => {
  const converted = {} as { [key: string]: string | string[] };
  filters.forEach(val => {
    if (ORDER_FILTERS_ALLOWED_MULTIPLES.includes(val.field)) {
      if (converted[val.field]) {
        converted[val.field] = converted[val.field].concat(',', val.value);
      } else {
        converted[val.field] = val.value;
      }
    } else {
      converted[val.field] = val.value;
    }
  });
  return converted;
};
