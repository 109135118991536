export enum AppFeature {
  DataImport = 'DataImport'
}

const features = process.env.REACT_APP_FEATURES
  ? process.env.REACT_APP_FEATURES.split(',').map(string => string.trim())
  : [];

export function hasFeatureEnabled(feature: AppFeature) {
  return features.includes(feature);
}
