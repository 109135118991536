import { Stack, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';

import { DATE_TIME_FORMAT } from 'app/constants';
import { DataImportOperationSummaryItem } from 'app/types/dataImport';
import { formatDate } from 'app/utils/date-utils';
import { formatNumber } from 'app/utils/format-utils';

import { DataImportState } from './DataImportState';
import { DownloadErrorsFile } from './DownloadErrorsFile';
import { FailedImportDetails } from './Modals';

export const generateDataImportTableColumns = (): GridColDef[] => [
  {
    field: 'filename',
    filterable: false,
    headerName: 'Filename',
    flex: 1,
    renderCell: (params: GridRenderCellParams<DataImportOperationSummaryItem>) => (
      <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='body2' noWrap={true} title={params.row.zipFileName}>
          {params.row.zipFileName}
        </Typography>

        <FailedImportDetails dataImport={params.row} />
      </Stack>
    ),
    sortable: false,
    minWidth: 280
  },
  {
    field: 'state',
    filterable: false,
    headerName: 'State',
    width: 150,
    renderCell: (params: GridRenderCellParams<DataImportOperationSummaryItem>) => (
      <DataImportState state={params.row?.status} />
    )
  },
  {
    field: 'totalRecords',
    filterable: false,
    headerName: 'Total Records',
    width: 150,
    renderCell: (params: GridRenderCellParams<DataImportOperationSummaryItem>) => (
      <Typography variant='body2' noWrap={true}>
        {params.row.totalRecords && formatNumber(params.row.totalRecords)}
      </Typography>
    ),
    sortable: false
  },
  {
    field: 'totalErrors',
    filterable: false,
    headerName: 'Total Errors',
    width: 150,
    renderCell: (params: GridRenderCellParams<DataImportOperationSummaryItem>) => (
      <Typography variant='body2' noWrap={true}>
        {params.row.totalErrors && formatNumber(params.row.totalErrors)}
        {Boolean(params.row.totalErrors && params.row.totalErrors > 0) && (
          <DownloadErrorsFile dataImport={params.row} />
        )}
      </Typography>
    ),
    sortable: false
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    filterable: false,
    sortable: true,
    width: 140,
    valueGetter: (params: GridValueGetterParams<DataImportOperationSummaryItem>) =>
      params.row?.createdAt && formatDate(params.row?.createdAt, DATE_TIME_FORMAT)
  },
  {
    field: 'startedAt',
    headerName: 'Started At',
    filterable: false,
    sortable: true,
    width: 140,
    valueGetter: (params: GridValueGetterParams<DataImportOperationSummaryItem>) =>
      params.row?.startedAt && formatDate(params.row?.startedAt, DATE_TIME_FORMAT)
  },
  {
    field: 'finishedAt',
    headerName: 'Finished At',
    filterable: false,
    sortable: true,
    width: 140,
    valueGetter: (params: GridValueGetterParams<DataImportOperationSummaryItem>) =>
      params.row?.finishedAt && formatDate(params.row?.finishedAt, DATE_TIME_FORMAT)
  }
];
