import { FunctionComponent, PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  wrapper: {
    marginBottom: theme.spacing(2),

    '&:last-of-type': {
      marginBottom: 0
    }
  },
  inlineWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(2)
  },
  label: {
    textTransform: 'capitalize'
  }
}));

export interface LabelValueProps extends PropsWithChildren {
  label?: string;
  labelColor?: string;
  valueColor?: string;
  inline?: boolean;
}

export const LabelValue: FunctionComponent<LabelValueProps> = ({
  label,
  labelColor = 'grey.600',
  valueColor = 'black',
  inline,
  children
}) => {
  const { classes } = useStyles();

  return (
    <div className={inline ? classes.inlineWrapper : classes.wrapper}>
      <Typography variant='subtitle2' color={labelColor} className={classes.label}>
        {label}
      </Typography>
      <Typography color={valueColor} variant={children ? 'body1' : 'emptyValue'}>
        {children || 'n/a'}
      </Typography>
    </div>
  );
};
