import { useMutation } from 'react-query';
import { AxiosError, isAxiosError } from 'axios';

import { SWITCH_ORDERS_API_ROOT } from 'app/constants/routes';
import { useAxiosWrapper } from 'app/hooks/useAxiosWrapper';
import { ErrorResponse, MatchRequestValues, SwitchOrderFull } from 'app/types';
import { sanitiseNewMatchRequest } from 'app/utils/order-utils';

type MatchRequestMutationOptions = MatchRequestValues;

export const useMatchRequestMutation = () => {
  const axios = useAxiosWrapper();

  return useMutation<SwitchOrderFull, ErrorResponse, MatchRequestMutationOptions, ErrorResponse>({
    mutationKey: 'useMatchRequestMutation',
    mutationFn: async (requestBody: MatchRequestMutationOptions) =>
      axios
        .post<SwitchOrderFull>(
          SWITCH_ORDERS_API_ROOT,
          sanitiseNewMatchRequest({
            ...requestBody
          })
        )
        .then(response => response.data)
        .catch((error: AxiosError<ErrorResponse>) => {
          if (isAxiosError(error)) {
            throw error.response?.data;
          } else {
            throw error;
          }
        })
  });
};
