import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

import { LOCALSTORAGE_RCP_VALUE } from 'app/constants/app';
import { useAuth } from 'app/hooks/useAuth';
import { isStringArray } from 'app/utils/misc-utils';

type IdentityContextType = {
  rcpId: string;
  rcpOptions: string[];
  email?: string;
  username?: string;
  setSelectedRcp: (val: string) => void;
};

const IdentityContext = createContext<IdentityContextType>({
  rcpId: '',
  rcpOptions: [],
  setSelectedRcp: () => {}
});

export const IdentityProvider = (props: PropsWithChildren) => {
  const auth = useAuth();

  const [selectedRcpId, setSelectedRcpId] = useState('');

  const rcpOptions = useMemo(
    () => (isStringArray(auth.user?.profile.rcps) ? (auth.user?.profile.rcps as string[]) : []),
    [auth]
  );
  const email = useMemo(() => auth.user?.profile.email, [auth]);
  const username = useMemo(() => auth.user?.profile.preferred_username, [auth]);

  useEffect(() => {
    if (rcpOptions && rcpOptions.length) {
      const defaultVal = isStringArray(auth.user?.profile.rcps)
        ? (auth.user?.profile.rcps as string[])[0]
        : auth.user?.profile.rcps;

      const localVal = localStorage.getItem(LOCALSTORAGE_RCP_VALUE);

      // if we have a value in localStorage (and it's valid), then use that
      if (!!localVal && defaultVal !== localVal && !!rcpOptions.find(val => val === localVal)) {
        setSelectedRcpId(typeof localVal === 'string' ? localVal : '');
      } else {
        setSelectedRcpId(typeof defaultVal === 'string' ? defaultVal : '');
      }
    }
  }, [auth, rcpOptions]);

  return (
    <IdentityContext.Provider
      value={{
        rcpId: selectedRcpId,
        rcpOptions,
        email,
        username,
        setSelectedRcp: (val: string) => {
          localStorage.setItem(LOCALSTORAGE_RCP_VALUE, val);
          setSelectedRcpId(val);
        }
      }}
    >
      {props.children}
    </IdentityContext.Provider>
  );
};

export const useIdentity = () => useContext(IdentityContext);
