import React, { FunctionComponent } from 'react';
import { IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { Modal, ModalActions, ModalContent, useModal } from 'app/components';
import { DATA_IMPORT_STATES } from 'app/constants';
import { DataImportOperationSummaryItem } from 'app/types';
import { formatError } from 'app/utils/error-utils';

export const FailedImportDetails: FunctionComponent<{
  dataImport: DataImportOperationSummaryItem;
}> = ({ dataImport }) => {
  if (dataImport.status !== DATA_IMPORT_STATES.FAILED || !dataImport.failureDetails) {
    return null;
  }

  const { open, handleOpen, handleClose } = useModal();

  const { errorCode, errorMessage, errorDetailedMessage } = formatError(dataImport.failureDetails);

  return (
    <>
      <IconButton size='small' onClick={handleOpen}>
        <HelpOutlineOutlinedIcon />
      </IconButton>

      <Modal modalTitle='Failed Import Details' isOpen={open} onClose={handleClose}>
        <ModalContent>
          <Typography variant='body1'>
            <strong>Filename:</strong> {dataImport.zipFileName}
          </Typography>
          <br />
          <Typography variant='body1'>
            <strong>{errorCode}</strong> {errorMessage}
          </Typography>
          <br />
          <Typography variant='body1'>{errorDetailedMessage}</Typography>
        </ModalContent>

        <ModalActions>
          <Button onClick={handleClose}>Close</Button>
        </ModalActions>
      </Modal>
    </>
  );
};
