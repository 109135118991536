import { FunctionComponent } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { Address } from 'app/types';
import { formatAddress } from 'app/utils/format-utils';

const useStyles = makeStyles()(() => ({
  address: {
    width: '100%'
  }
}));

export interface OrderAddressProps {
  address?: Address | null;
}

export const OrderAddress: FunctionComponent<OrderAddressProps> = ({ address }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.address}>
      {address ? (
        <>
          <Typography variant='body2' noWrap={true} title={formatAddress(address, true)}>
            {formatAddress(address)}
          </Typography>
          <Typography variant='body2'>
            {address.postcode}
            {address.uprn && `, ${address.uprn}`}
          </Typography>
        </>
      ) : (
        <Typography variant='emptyValue'>Unavailable</Typography>
      )}
    </div>
  );
};
