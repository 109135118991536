import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Button, IconButton, Link, MenuItem, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { makeStyles } from 'tss-react/mui';

import {
  ErrorBanner,
  FormikRcpAutocomplete,
  FormikSelect,
  FormikTextField,
  PageLoadingIndicator,
  Panel
} from 'app/components';
import {
  NOTIFICATIONS,
  SERVICE_TYPES,
  SWITCH_ACTION_OPTIONS,
  SWITCH_STATES,
  URLS
} from 'app/constants';
import { useIdentity } from 'app/hooks/useIdentity';
import { useFetchOrder, useFetchOrderNotifications } from 'app/hooks/useOrderQueries';
import { RequestFailure, SwitchOrderNotification } from 'app/types';
import { ErrorResponse } from 'app/types/error';
import { MatchRequestValues } from 'app/types/matchRequest';
import { formatError } from 'app/utils/error-utils';
import { getHelperUrlForProvider } from 'app/utils/provider-utils';
import { getServiceTypeLabel } from 'app/utils/service-utils';

import { MatchRequestLoadingModal } from './MatchRequestLoadingModal';
import { ServiceActionButtonGroup } from './ServiceActionButtonGroup';
import { useMatchRequestMutation } from './useMatchRequestQueries';
import { validationSchema } from './validation';

const useStyles = makeStyles()(theme => ({
  hiddenGridItem: {
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  providerLink: {
    alignSelf: 'center'
  },
  serviceDelete: {
    margin: theme.spacing(0.5, 0, 0, 2)
  },
  buttons: {
    justifyContent: 'flex-end'
  }
}));

const INITIAL_VALUES: MatchRequestValues = {
  lrcpId: '',
  grcpId: '',
  name: '',
  address: {
    singleLineAddress: '',
    postTown: '',
    postcode: ''
  },
  services: [
    {
      serviceType: SERVICE_TYPES.BROADBAND,
      action: SWITCH_ACTION_OPTIONS.CEASE
    }
  ]
};

export const CreateMatchRequest: FunctionComponent = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { rcpId } = useIdentity();
  const params = useParams();

  const queryOptions = useMemo(
    () => ({
      switchId: params.id || ''
    }),
    [params.id]
  );

  const { isFetching: isOrderFetching, data: orderData } = useFetchOrder(
    queryOptions,
    !!queryOptions.switchId
  );

  const initialValues: MatchRequestValues = useMemo(() => {
    if (orderData) {
      return {
        ...INITIAL_VALUES,
        accountRef: orderData.accountRef || '',
        lrcpId: orderData.lrcp.rcpId || '',
        name: orderData.customerSurname || '',
        address: {
          singleLineAddress: orderData.customerAddress?.singleLineAddress || '',
          postTown: orderData.customerAddress?.postTown || '',
          postcode: orderData.customerAddress?.postcode || '',
          uprn: orderData.customerAddress?.uprn || ''
        },
        services: orderData.services || INITIAL_VALUES.services
      };
    } else {
      return INITIAL_VALUES;
    }
  }, [orderData]);

  const { mutate } = useMatchRequestMutation();

  const [uiReference, setUiReference] = useState<string | undefined>();
  const [errorResponse, setErrorResponse] = useState<ErrorResponse | undefined>();
  const [enablePollRequest, setEnablePollRequest] = useState(false);
  const [newSwitchId, setNewSwitchId] = useState<string>('');
  const [rcpHelperUrl, setRcpHelperUrl] = useState<string | undefined>();

  useEffect(() => {
    setUiReference(undefined);
    setErrorResponse(undefined);
    setEnablePollRequest(false);
    setNewSwitchId('');
  }, [rcpId]);

  // Step 1 - call API to submit the Match Request
  const handleSubmit = (values: MatchRequestValues, actions: FormikHelpers<MatchRequestValues>) => {
    const correlationId = ![
      SWITCH_STATES.MATCH_CONFIRMED,
      SWITCH_STATES.ORDER_FAILED,
      SWITCH_STATES.UPDATE_FAILED,
      SWITCH_STATES.TRIGGER_FAILED
    ].includes(orderData?.state as SWITCH_STATES)
      ? uiReference ?? orderData?.uiReference
      : undefined;

    mutate(
      { ...values, grcpId: rcpId, uiReference: correlationId },
      {
        onSuccess: newOrder => {
          // make a note of these values
          setUiReference(newOrder.uiReference);
          setNewSwitchId(newOrder.switchId);
          // now start polling for notifications
          setEnablePollRequest(true);
        },
        onError: err => {
          setErrorResponse(formatError(err));
          setEnablePollRequest(false);
        },
        onSettled: () => {
          actions.setSubmitting(false);
        }
      }
    );
  };

  // Step 2 - poll the API until a notification arrives for the new Match Request
  // - the notification we are interested in is either a MatchConfirmation or a SwitchRequestFailure
  const { isFetching: isFetchingNewOrderData, data: newOrderData } = useFetchOrderNotifications(
    { switchId: newSwitchId },
    enablePollRequest,
    (data?: SwitchOrderNotification[]) =>
      !data ||
      data.length == 0 ||
      data.find(
        val =>
          val.notificationType !== NOTIFICATIONS.MatchConfirmation &&
          val.notificationType !== NOTIFICATIONS.SwitchRequestFailure
      )
        ? 3000
        : false
  );

  // Step 3 - we have the notification, so now either proceed to next page OR show an error message
  useEffect(() => {
    if (!isFetchingNewOrderData && !!newOrderData?.length) {
      const confirmation = newOrderData.find(
        val => val.notificationType === NOTIFICATIONS.MatchConfirmation
      );
      const failure = newOrderData.find(
        val => val.notificationType === NOTIFICATIONS.SwitchRequestFailure
      );

      if (confirmation) {
        // disable the polling request (should already be disabled anyway)
        setEnablePollRequest(false);
        navigate(`/${URLS.GENERATE_ORDER.replace(':id', newSwitchId)}`);
      } else if (failure) {
        // disable the polling request (should already be disabled anyway)
        setEnablePollRequest(false);
        setErrorResponse(formatError((failure.content as RequestFailure).failureDetails));
      }
    }
  }, [isFetchingNewOrderData, navigate, newOrderData, newSwitchId]);

  if (isOrderFetching) {
    return <PageLoadingIndicator />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={(values, actions) => {
        setErrorResponse(undefined);
        handleSubmit(values, actions);
      }}
    >
      {({ isSubmitting, isValid, values }) => {
        return (
          <Form>
            <Stack gap={2}>
              <Typography variant='h3'>Create Match Request</Typography>
              {errorResponse && <ErrorBanner error={errorResponse} />}
              <MatchRequestLoadingModal
                show={isSubmitting || enablePollRequest}
                onErrorTimeout={() => {
                  setEnablePollRequest(false);
                  setErrorResponse({
                    errorCode: '',
                    errorMessage: 'An unknown error has occurred.',
                    errorDetailedMessage: 'The match request took too long to complete.'
                  });
                }}
              />

              <Panel title='Customer Details'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormikTextField name='name' label='Surname' required />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.hiddenGridItem}></Grid>

                  <Grid item xs={12}>
                    <FormikTextField
                      name='address.singleLineAddress'
                      label='Address'
                      required
                      helperText='Use a comma to separate each line of the address'
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField name='address.postTown' label='Town / City' required />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.hiddenGridItem}></Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField name='address.postcode' label='Postcode' required />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.hiddenGridItem}></Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField name='address.uprn' label='UPRN' />
                  </Grid>
                </Grid>
              </Panel>
              <Panel title='Provider Details'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormikRcpAutocomplete
                      name='lrcpId'
                      label='Losing Provider'
                      onChange={val => setRcpHelperUrl(getHelperUrlForProvider(val))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.hiddenGridItem}></Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikTextField name='accountRef' label='Account Ref' />
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.providerLink}>
                    {rcpHelperUrl && (
                      <Link href={rcpHelperUrl} underline='hover' target='_blank' rel='noopener'>
                        Where can I find the account and service details?
                      </Link>
                    )}
                  </Grid>

                  <FieldArray name='services'>
                    {({ remove, replace, push }) => (
                      <>
                        {values.services.map((service, index) => (
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={2}
                            key={index}
                            alignItems='baseline'
                          >
                            <Grid item xs={6} md={3}>
                              <FormikSelect
                                name={`services.${index}.serviceType`}
                                label='Service Type'
                                required
                                onChange={val =>
                                  replace(index, {
                                    serviceType: val,
                                    serviceIdentifier: service.serviceIdentifier,
                                    action: SWITCH_ACTION_OPTIONS.CEASE
                                  })
                                }
                              >
                                <MenuItem value={SERVICE_TYPES.BROADBAND}>
                                  {getServiceTypeLabel(SERVICE_TYPES.BROADBAND)}
                                </MenuItem>
                                <MenuItem value={SERVICE_TYPES.VOICE}>
                                  {getServiceTypeLabel(SERVICE_TYPES.VOICE)}
                                </MenuItem>
                              </FormikSelect>
                            </Grid>
                            <Grid item xs={6} md={3}>
                              <FormikTextField
                                name={`services.${index}.serviceIdentifier`}
                                label='Identifier'
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {service.serviceType && (
                                <ServiceActionButtonGroup
                                  type={service.serviceType}
                                  name={`services.${index}.action`}
                                />
                              )}

                              <IconButton
                                className={classes.serviceDelete}
                                data-testid={`services.${index}.remove`}
                                onClick={() => remove(index)}
                              >
                                <HighlightOffIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}

                        <Grid item xs={12}>
                          <Button
                            variant='outlined'
                            startIcon={<AddIcon />}
                            onClick={() =>
                              push(
                                values.services.length === 1
                                  ? {
                                      serviceType: SERVICE_TYPES.VOICE,
                                      serviceIdentifier: undefined,
                                      action: SWITCH_ACTION_OPTIONS.CEASE
                                    }
                                  : {
                                      serviceType: '',
                                      serviceIdentifier: undefined,
                                      action: ''
                                    }
                              )
                            }
                          >
                            Add Service
                          </Button>
                        </Grid>
                      </>
                    )}
                  </FieldArray>
                </Grid>
              </Panel>
              <Stack direction='row' gap={2} className={classes.buttons}>
                <Button
                  variant='text'
                  disabled={isSubmitting || enablePollRequest}
                  component={RouterLink}
                  to={`/${URLS.ORDERS}`}
                  data-testid='cancel-button'
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  type='submit'
                  disabled={isSubmitting || enablePollRequest || !isValid}
                  data-testid='submit-button'
                >
                  Create Match Request
                </Button>
              </Stack>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
