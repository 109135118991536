import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';

import { LabelValue, Panel } from 'app/components';
import { DATE_TIME_FORMAT } from 'app/constants/dates';
import { CustomFields } from 'app/types/notifications';
import { SwitchOrderFull } from 'app/types/switchOrder';
import { formatDate } from 'app/utils/date-utils';

export const AdditionalDetailsPanel: FunctionComponent<{
  order: SwitchOrderFull;
  customFields?: CustomFields[];
}> = ({ order, customFields }) => (
  <Panel title='Additional Details'>
    <Grid container spacing={2}>
      <Grid item xs>
        <LabelValue label='Last Updated At'>
          {formatDate(order.lastUpdatedAt, DATE_TIME_FORMAT)}
        </LabelValue>
        <LabelValue label='Created At'>{formatDate(order.createdAt, DATE_TIME_FORMAT)}</LabelValue>
      </Grid>
      <Grid item xs>
        {customFields &&
          customFields.map(({ type, value }) => (
            <LabelValue key={`${type}-${value}`} label={type}>
              {value}
            </LabelValue>
          ))}
      </Grid>
    </Grid>
  </Panel>
);
