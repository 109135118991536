export const FAILURE_REASONS = {
  CEASE: {
    key: '1214',
    value:
      'There is an open cease order which is past the point of no return and cannot be cancelled.'
  },
  MODIFY: {
    key: '1215',
    value:
      'There is an open modify order which is past the point of no return and cannot be cancelled.'
  }
};
