import { FunctionComponent, useState } from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

import { Modal, ModalActions, ModalContent, useModal } from 'app/components';
import { useConfirmOrderMutation } from 'app/hooks/useOrderQueries';
import { ErrorResponse, SwitchOrderFull } from 'app/types';
import { formatError } from 'app/utils/error-utils';

export const ConfirmOrder: FunctionComponent<{ order: SwitchOrderFull }> = ({ order }) => {
  const { mutate, isLoading } = useConfirmOrderMutation();
  const navigate = useNavigate();
  const { open, handleOpen, handleClose } = useModal();
  const [error, setError] = useState<ErrorResponse | undefined>();

  const handleSubmit = async () => {
    setError(undefined);

    mutate(
      {
        id: order.switchId
      },
      {
        onSuccess: () => {
          handleClose();
          navigate(0);
        },
        onError: err => {
          setError(formatError(err));
        }
      }
    );
  };

  return (
    <>
      <Button variant='contained' onClick={handleOpen}>
        Confirm Order
      </Button>

      <Modal modalTitle='Confirm Order' modalError={error} isOpen={open} onClose={handleClose}>
        <ModalContent>
          <Typography variant='body1'>
            You are confirming that this switch order is being accepted.
            <br />
            This action cannot be reversed.
          </Typography>
        </ModalContent>

        <ModalActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            data-testid='confirm'
            variant='contained'
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Confirm'}
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};
