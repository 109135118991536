import React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const DateFieldMaskInput = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask='AB00-Y0-X0 – AB00-Y0-X0'
        definitions={{
          '0': /[0-9]/,
          X: /[0-3]/,
          Y: /[0-1]/,
          A: /2/,
          B: /0/
        }}
        inputRef={ref}
        onAccept={(value: string) => {
          onChange({ target: { name: props.name, value } });
        }}
        overwrite
      />
    );
  }
);
