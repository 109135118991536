import { FunctionComponent, useMemo } from 'react';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ConstructionIcon from '@mui/icons-material/Construction';
import WarningIcon from '@mui/icons-material/Warning';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import { LabelValue } from 'app/components';
import { SwitchAction } from 'app/components/Orders';
import { ServiceDetailsData } from 'app/types/serviceDetails';
import { dateIsInFuture, formatDateTime } from 'app/utils/date-utils';
import { isOpenOrder } from 'app/utils/order-utils';
import {
  getIdentifierTypeLabel,
  getOpenOrderTypeValues,
  getServiceTypeLabel
} from 'app/utils/service-utils';

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: theme.spacing(2),
    alignContent: 'baseline'
  },
  leftColumn: {
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.divider
  },
  rightColumn: {},
  topRow: {
    alignItems: 'center'
  },
  row: {
    paddingBottom: theme.spacing(1)
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  openOrderAlert: {
    paddingRight: 0,
    width: '100%',

    '&>.MuiAlert-message': {
      width: '100%'
    }
  },
  openOrderAlertRightColumn: {
    marginRight: theme.spacing(-2),
    paddingRight: theme.spacing(2),
    width: `calc(100% + ${theme.spacing(2)})`
  },
  openOrderAlertIcon: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export const ServiceDetailItem: FunctionComponent<{
  service?: ServiceDetailsData;
  rightColumn?: boolean;
}> = ({ service, rightColumn }) => {
  const { classes } = useStyles();

  const hasOpenOrder = useMemo(
    () => service?.fields.openOrder && isOpenOrder(service.fields.openOrder),
    [service?.fields.openOrder]
  );

  const minTermExpiryIsInFuture = useMemo(() => {
    const field = service?.fields.fields.find(({ name }) => name === 'MinTermExpiry');
    return field && dateIsInFuture(field.value);
  }, [service?.fields.fields]);

  return (
    <Grid
      container
      className={clsx(classes.container, rightColumn ? classes.rightColumn : classes.leftColumn)}
      item
      xs={6}
      columnSpacing={2}
      rowSpacing={1}
    >
      <Grid container item xs={12} className={classes.topRow}>
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant='h6' className={classes.title}>
            {minTermExpiryIsInFuture && (
              <Tooltip
                title='Account is within minimum term commitment'
                placement='top'
                style={{ cursor: 'help' }}
              >
                <WarningIcon />
              </Tooltip>
            )}
            {service && getServiceTypeLabel(service.type)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          {service && <SwitchAction action={service.action} />}
        </Grid>
      </Grid>
      {hasOpenOrder && (
        <Alert
          icon={false}
          severity='warning'
          className={clsx(
            classes.openOrderAlert,
            rightColumn ? classes.openOrderAlertRightColumn : ''
          )}
        >
          <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
            <Grid item xs={10}>
              <LabelValue label='Open Order Type' labelColor='grey.800'>
                {getOpenOrderTypeValues(service?.fields.openOrder!.orderType || '')}
              </LabelValue>
            </Grid>
            <Grid item xs={2} className={classes.openOrderAlertIcon}>
              <ConstructionIcon fontSize='large' />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <LabelValue label='Expected Completion' labelColor='grey.800'>
                {service?.fields.openOrder?.expectedCompletion}
              </LabelValue>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <LabelValue label='PONR' labelColor='grey.800'>
                {formatDateTime(service?.fields.openOrder?.ponr)}
              </LabelValue>
            </Grid>
          </Grid>
        </Alert>
      )}
      <Grid container item xs={12} columnSpacing={2} rowSpacing={1} className={classes.row}>
        {(service?.fields.fields || []).map(({ name, value }, valIndex) => (
          <Grid item xs={12} md={6} lg={4} key={`serviceIdentifier-${valIndex}`}>
            <LabelValue label={getIdentifierTypeLabel(name)}>{value}</LabelValue>
          </Grid>
        ))}
      </Grid>
      <Grid container item xs={12} columnSpacing={2} rowSpacing={1} className={classes.row}>
        {(service?.fields.customFields || []).map(({ name, value }, valIndex) => (
          <Grid item xs={12} md={6} lg={4} key={`serviceIdentifier-${valIndex}`}>
            <LabelValue label={getIdentifierTypeLabel(name)}>{value}</LabelValue>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
