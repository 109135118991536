import { FunctionComponent, MouseEvent, useState } from 'react';
import { Popover } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import axios from 'axios';
import { makeStyles } from 'tss-react/mui';

import { ErrorBanner } from 'app/components';
import { ERRORS_CSV_DOWNLOAD_API } from 'app/constants';
import { DataImportOperationSummaryItem, ErrorResponse } from 'app/types';
import { formatError } from 'app/utils/error-utils';

const useStyles = makeStyles()(theme => ({
  download: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#bde0fc',
    color: theme.palette.text.primary
  }
}));

export const DownloadErrorsFile: FunctionComponent<{
  dataImport: DataImportOperationSummaryItem;
}> = ({ dataImport }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { classes } = useStyles();
  const [error, setError] = useState<ErrorResponse | null>();

  const handleDownload = async (event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLElement>) => {
    setError(null);
    try {
      const response = await axios.get(
        ERRORS_CSV_DOWNLOAD_API.replace(':id', dataImport.importId),
        {
          responseType: 'blob'
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `error_report_${dataImport.importId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setError(formatError(error));
      setAnchorEl(event.target as HTMLElement);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        className={classes.download}
        size='small'
        onClick={handleDownload}
        color='primary'
      >
        <CloudDownloadOutlinedIcon fontSize='small' />
      </IconButton>
      <Popover
        id={open ? 'error-popover' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {error && <ErrorBanner error={error} />}
      </Popover>
    </>
  );
};
