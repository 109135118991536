import { FunctionComponent, useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';

import { FormikDatePicker, Modal, ModalActions, ModalContent, useModal } from 'app/components';
import { SWITCH_STATES } from 'app/constants';
import { useTriggerSwitchMutation } from 'app/hooks/useOrderQueries';
import { ErrorResponse, SwitchOrderFull } from 'app/types';
import { formatDate } from 'app/utils/date-utils';
import { formatError } from 'app/utils/error-utils';

import { getValidationTriggerSwitch } from './validation';

interface FormikValuesProps {
  activationDate: string;
}

const TODAY = new Date(new Date().setHours(0, 0, 0, 0));

export const TriggerSwitch: FunctionComponent<{ order: SwitchOrderFull }> = ({ order }) => {
  const { mutateAsync } = useTriggerSwitchMutation();
  const navigate = useNavigate();
  const { open, handleOpen, handleClose } = useModal();
  const [error, setError] = useState<ErrorResponse | undefined>();

  const handleSubmit = async (values: FormikValuesProps) => {
    setError(undefined);

    await mutateAsync(
      {
        id: order.switchId,
        activationDate: formatDate(values.activationDate)
      },
      {
        onSuccess: () => {
          handleClose();
          navigate(0);
        },
        onError: err => {
          setError(formatError(err));
        }
      }
    );
  };

  return (
    <>
      <Button
        variant='contained'
        disabled={order.state === SWITCH_STATES.UPDATE_REQUESTED}
        onClick={handleOpen}
      >
        Trigger Switch
      </Button>

      <Modal modalTitle='Trigger Switch' modalError={error} isOpen={open} onClose={handleClose}>
        <Formik
          enableReinitialize
          initialValues={{ activationDate: '' }}
          validationSchema={getValidationTriggerSwitch(
            dayjs(order.createdAt).toISOString(),
            dayjs(TODAY).toISOString()
          )}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              <ModalContent>
                <FormikDatePicker
                  minDate={dayjs(order.createdAt)}
                  maxDate={dayjs(TODAY)}
                  name='activationDate'
                  label='Effective Trigger Date *'
                />
              </ModalContent>

              <ModalActions>
                <Button onClick={handleClose}>Close</Button>
                <Button
                  data-testid='trigger-switch'
                  variant='contained'
                  disabled={!isValid || isSubmitting}
                  type='submit'
                >
                  {isSubmitting ? <CircularProgress size={24} /> : 'Trigger switch'}
                </Button>
              </ModalActions>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
