import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProviderProps } from 'react-oidc-context';
import axios from 'axios';

import App from 'app/App';
import { ORIGINAL_DESTINATION } from 'app/constants/app';
import { AppConfigProvider } from 'app/hooks/useAppConfig';
import { AuthProvider } from 'app/hooks/useAuth';
import { AppConfig } from 'app/types/appConfig';

import reportWebVitals from './reportWebVitals';

async function enableMocking() {
  if (process.env.REACT_APP_ENABLE_MOCK_SERVICE_WORKER === 'true') {
    const { worker } = await import('./mock-api/browser');

    // `worker.start()` returns a Promise that resolves
    // once the Service Worker is up and ready to intercept requests.
    return worker.start({
      onUnhandledRequest(req, print) {
        if (
          req.url.includes('static/media') ||
          req.url.includes('hot-update') ||
          req.url.includes('/logo192')
        )
          return;
        print.warning();
      }
    });
  }
  return;
}

function bootstrapApp(config: AppConfig) {
  enableMocking().then(() => {
    const appConfig = {
      ...config,
      apiUrl: process.env.REACT_APP_BASE_API || config.apiUrl
    };

    const oidcConfig: AuthProviderProps = {
      authority: process.env.REACT_APP_AUTHORITY || config.keycloak.authority,
      client_id: process.env.REACT_APP_CLIENT_ID || config.keycloak.client_id,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI || config.keycloak.redirect_uri,
      post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI || config.keycloak.redirect_uri,
      resource: process.env.REACT_APP_RESOURCE || config.keycloak.resource,
      scope: process.env.REACT_APP_SCOPE || config.keycloak.scope,
      onSigninCallback: (): void => {
        window.history.replaceState({}, document.title, window.location.pathname);

        const url = window.sessionStorage.getItem(ORIGINAL_DESTINATION);
        if (url) {
          window.sessionStorage.removeItem(ORIGINAL_DESTINATION);
          window.location.replace(url);
        }
      }
    };

    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <React.StrictMode>
        <AppConfigProvider config={appConfig}>
          <AuthProvider {...oidcConfig}>
            <App />
          </AuthProvider>
        </AppConfigProvider>
      </React.StrictMode>
    );
  });
}

// Get the config file, then bootstrap the app
axios
  .get<AppConfig>(process.env.REACT_APP_CONFIG_URL!)
  .then(response => bootstrapApp(response.data))
  .catch(err => {
    document.getElementById('root')!.innerHTML = `
      <p>Cannot load application configuration</p>
    `;
    console.error(
      'Cannot load application configuration from ',
      process.env.REACT_APP_CONFIG_URL,
      err
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
