import { TimelineDotProps } from '@mui/lab/TimelineDot';

import { OrderTimelineProps } from 'app/components';
import { NOTIFICATIONS } from 'app/constants/notifications';
import { SwitchOrderNotification } from 'app/types';

import { formatDate, formatDateTime } from './date-utils';
import { formatErrorDetails } from './error-utils';

export const mapNotificationTypeToColor = (
  notification: SwitchOrderNotification
): TimelineDotProps['color'] => {
  switch (notification.notificationType) {
    case NOTIFICATIONS.MatchConfirmation:
    case NOTIFICATIONS.SwitchingImplications:
    case NOTIFICATIONS.OrderRequest:
    case NOTIFICATIONS.OrderConfirmation:
    case NOTIFICATIONS.OrderUpdateConfirmation:
    case NOTIFICATIONS.OrderTriggerConfirmation:
    case NOTIFICATIONS.OrderTriggerRequest:
      return 'primary';
    case NOTIFICATIONS.OrderCancelConfirmation:
    case NOTIFICATIONS.SwitchRequestFailure:
    case NOTIFICATIONS.OrderFailure:
    case NOTIFICATIONS.OrderTriggerFailure:
    case NOTIFICATIONS.OrderUpdateFailure:
    case NOTIFICATIONS.OrderExpiration:
    case NOTIFICATIONS.OrderCancelFailure:
    case NOTIFICATIONS.HubMessageDeliveryFailure:
    case NOTIFICATIONS.HubMessageProcessingFailure:
      return 'error';
    default:
      return 'primary';
  }
};

export const mapNotificationTypeToText = ({
  notificationType,
  content
}: SwitchOrderNotification): string => {
  switch (notificationType) {
    case NOTIFICATIONS.MatchConfirmation:
    case NOTIFICATIONS.SwitchingImplications:
      return 'Matched';
    case NOTIFICATIONS.OrderRequest:
      return 'Order Requested';
    case NOTIFICATIONS.OrderConfirmation:
      return 'switchOrderConfirmationDetails' in content
        ? `Ordered for ${formatDate(content.switchOrderConfirmationDetails.plannedSwitchDate)}`
        : 'Ordered';
    case NOTIFICATIONS.OrderUpdateConfirmation:
      return 'orderUpdateConfirmationDetails' in content
        ? `Updated Switch Date for ${formatDate(
            content.orderUpdateConfirmationDetails?.plannedSwitchDate
          )}`
        : 'Updated';
    case NOTIFICATIONS.OrderUpdateFailure:
      return 'failureDetails' in content
        ? `Order Update Failed. ${formatErrorDetails(content.failureDetails)}`
        : 'Order Update Failed';
    case NOTIFICATIONS.OrderTriggerRequest:
      return 'orderTriggerRequestDetails' in content
        ? `Trigger Requested for ${formatDate(content.orderTriggerRequestDetails.activationDate)}`
        : 'Trigger Requested';
    case NOTIFICATIONS.OrderTriggerConfirmation:
      return 'Order Completed';
    case NOTIFICATIONS.OrderCancelConfirmation:
      return 'orderCancellationDetails' in content
        ? `Cancelled on ${formatDate(content.orderCancellationDetails.cancelledAt)}`
        : 'Cancelled';
    case NOTIFICATIONS.OrderCancelFailure:
      return 'failureDetails' in content
        ? `Order Cancel Failed. ${formatErrorDetails(content.failureDetails)}`
        : 'Order Cancel Failed';
    case NOTIFICATIONS.OrderExpiration:
      return 'switchOrderExpirationDetails' in content
        ? `Expired on ${formatDate(content.switchOrderExpirationDetails.expiredAt)}`
        : 'Expired';
    case NOTIFICATIONS.SwitchRequestFailure:
      return 'failureDetails' in content
        ? `Match Failed. ${formatErrorDetails(content.failureDetails)}`
        : 'Match Failed';
    case NOTIFICATIONS.OrderFailure:
      return 'failureDetails' in content
        ? `Order Failed. ${formatErrorDetails(content.failureDetails)}`
        : 'Order Failed';
    case NOTIFICATIONS.OrderTriggerFailure:
      return 'failureDetails' in content
        ? `Order Trigger Failed. ${formatErrorDetails(content.failureDetails)}`
        : 'Order Trigger Failed';
    case NOTIFICATIONS.HubMessageProcessingFailure:
      return 'failureDetails' in content
        ? `${formatErrorDetails(content.failureDetails)}`
        : 'Failed';
    case NOTIFICATIONS.HubMessageDeliveryFailure:
      return 'hubFailureData' in content ? `${content.hubFailureData.text}` : 'Failed';
    default:
      return notificationType;
  }
};

export const prepareTimelineData = (
  notifications: SwitchOrderNotification[]
): OrderTimelineProps => {
  return {
    list: notifications.map(notification => ({
      id: notification.notificationId,
      date: formatDateTime(notification.createdAt),
      color: mapNotificationTypeToColor(notification),
      text: mapNotificationTypeToText(notification)
    }))
  };
};
