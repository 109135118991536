import { AxiosError, isAxiosError } from 'axios';

import { ErrorResponse } from 'app/types';

const isApiError = (error: unknown): error is ErrorResponse =>
  typeof error === 'object' &&
  error !== null &&
  ('errorCode' in error || 'errorMessage' in error || 'errorDetailedMessage' in error);

const isHubError = (error: ErrorResponse) => {
  // Hub Errors have an errorCode in the format of 30XXX
  return error.errorCode && error.errorCode.startsWith('30');
};

const isComplexString = (message: unknown): boolean => {
  try {
    const parsedMessage = JSON.parse(message as string);
    return typeof parsedMessage === 'object' && parsedMessage !== null;
  } catch (error) {
    return false;
  }
};

const formatErrorCode = (errorCode: string): string =>
  errorCode.startsWith('5') && errorCode.length > 4
    ? `OTS Fault Code ${errorCode.substring(1)}: `
    : `Error Code ${errorCode}: `;

export const formatErrorDetails = (details: ErrorResponse) =>
  details ? `${formatError(details).errorCode} ${formatError(details).errorMessage}` : '';

export const getErrorMessage = (error: Error | AxiosError | unknown): unknown => {
  if (isAxiosError(error)) {
    return error.response?.data || error.message;
  }

  if (isApiError(error)) {
    return error;
  }

  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === 'string') {
    return error;
  }

  if ((error as Error)?.message) {
    return (error as Error).message;
  }

  return '';
};

export const formatError = (errorValue: unknown): ErrorResponse => {
  const error: unknown = getErrorMessage(errorValue);

  const result: ErrorResponse = {
    errorCode: '',
    errorMessage: 'An unexpected error occurred.',
    errorDetailedMessage: ''
  };

  if (isApiError(error)) {
    result.errorCode = error.errorCode ? formatErrorCode(error.errorCode) : '';
    result.errorMessage = error.errorMessage ?? 'An unexpected error occurred.';
    result.errorDetailedMessage = isComplexString(error.errorDetailedMessage)
      ? ''
      : error.errorDetailedMessage;

    // special handling for Hub Errors - we expect errorDetailedMessage to contain a JSON object
    // with the format: { "errorCode": "123", "errorText": "Text goes here" }
    if (isHubError(error)) {
      let hubErrorCode = undefined;
      let hubErrorText = undefined;

      try {
        const parsedMessage = JSON.parse(error.errorDetailedMessage as string);
        hubErrorCode = parsedMessage.errorCode;
        hubErrorText = parsedMessage.errorText;
      } catch (e) {
        // ignore the type error - we don't need to do anything with it
      }

      result.errorCode = formatErrorCode(error.errorCode);
      result.errorMessage = 'Hub Error';
      result.errorDetailedMessage = `Hub Error Code: ${
        hubErrorCode || 'Unavailable'
      }, Hub Error Details: ${hubErrorText || error.errorDetailedMessage}`;
    }
  } else {
    result.errorDetailedMessage = typeof error === 'string' ? error : JSON.stringify(error);
  }

  return result;
};
