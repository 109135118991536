import { FunctionComponent, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { Form, Formik } from 'formik';

import { FormikDatePicker, Modal, ModalActions, ModalContent, useModal } from 'app/components';
import { SWITCH_STATES } from 'app/constants';
import { useUpdateOrderMutation } from 'app/hooks/useOrderQueries';
import { ErrorResponse, SwitchOrderFull } from 'app/types';
import { formatDate } from 'app/utils/date-utils';
import { formatError } from 'app/utils/error-utils';

import { validationSwitchDate } from './validation';

interface FormikValuesProps {
  plannedSwitchDate: Dayjs;
}

export const ChangeSwitchDate: FunctionComponent<{ order: SwitchOrderFull }> = ({ order }) => {
  const { mutateAsync } = useUpdateOrderMutation();
  const navigate = useNavigate();
  const { open, handleOpen, handleClose } = useModal();
  const [error, setError] = useState<ErrorResponse | undefined>();

  const defaultPlannedSwitchDate = useMemo(
    () => order.plannedSwitchDate || new Date(),
    [order.plannedSwitchDate]
  );

  const handleSubmit = async (values: FormikValuesProps) => {
    setError(undefined);

    await mutateAsync(
      {
        id: order.switchId,
        plannedSwitchDate: formatDate(values.plannedSwitchDate)
      },
      {
        onSuccess: () => {
          handleClose();
          navigate(0);
        },
        onError: err => {
          setError(formatError(err));
        }
      }
    );
  };

  return (
    <>
      <Button
        variant='outlined'
        disabled={order.state === SWITCH_STATES.UPDATE_REQUESTED}
        onClick={handleOpen}
      >
        Update Details
      </Button>

      <Modal
        modalTitle='Update Planned Switch Date'
        modalError={error}
        isOpen={open}
        onClose={handleClose}
      >
        <Formik
          enableReinitialize
          initialValues={{ plannedSwitchDate: dayjs(defaultPlannedSwitchDate) }}
          validationSchema={validationSwitchDate}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting }) => (
            <Form>
              <ModalContent>
                <FormikDatePicker
                  disablePast
                  name='plannedSwitchDate'
                  label='Planned Switch Date *'
                />
              </ModalContent>

              <ModalActions>
                <Button onClick={handleClose}>Close</Button>
                <Button variant='contained' disabled={!isValid || isSubmitting} type='submit'>
                  {isSubmitting ? <CircularProgress size={24} /> : 'Update'}
                </Button>
              </ModalActions>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
