import dayjs, { Dayjs } from 'dayjs';

import { DATE_TIME_FORMAT, STANDARD_DATE_FORMAT } from 'app/constants/dates';

export type StringDateDayjs = string | Date | Dayjs;

export const formatDate = (date?: StringDateDayjs, format = STANDARD_DATE_FORMAT): string =>
  date ? dayjs(date).format(format) : '';

export const formatDateTime = (date?: StringDateDayjs, format = DATE_TIME_FORMAT): string =>
  formatDate(date, format);

export const dateIsInFuture = (date?: StringDateDayjs) =>
  date ? dayjs(date).isAfter(dayjs()) : false;
