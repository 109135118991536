import { FunctionComponent, useMemo } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import {
  CancelOrder,
  CeaseOfService,
  ChangeSwitchDate,
  ConfirmOrder,
  FailOrder,
  TriggerSwitch
} from 'app/components/OrderDetails/Modals';
import { OrderProviderType, OrderState } from 'app/components/Orders';
import { NOTIFICATIONS, PROVIDER_ROLES, SWITCH_STATES } from 'app/constants';
import { URLS } from 'app/constants/routes';
import { SwitchOrderNotification } from 'app/types';
import { SwitchOrderFull } from 'app/types/switchOrder';

import { CopyToClipboard } from '../CopyToClipboard';

interface OrderDetailsHeaderProps {
  isFetching: boolean;
  data?: SwitchOrderFull;
  isGenerateSwitchOrder?: boolean;
  notificationData?: SwitchOrderNotification[];
  hasOpenOrderTypeCease?: boolean;
  hasOpenOrderTypeModify?: boolean;
}

const useStyles = makeStyles()(theme => ({
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2)
  },
  leftContent: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  leftRow: {
    display: 'flex',
    alignItems: 'center'
  },
  rightContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    flex: 1
  },
  backBtn: {
    marginBottom: theme.spacing(1)
  },
  title: {
    marginRight: theme.spacing(1)
  },
  type: {
    marginRight: theme.spacing(2)
  }
}));

export const OrderDetailsPageHeader: FunctionComponent<OrderDetailsHeaderProps> = ({
  isFetching,
  data,
  notificationData,
  isGenerateSwitchOrder,
  hasOpenOrderTypeCease,
  hasOpenOrderTypeModify
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const allowActionsForGaining = useMemo(
    () =>
      [SWITCH_STATES.ORDER_CONFIRMED, SWITCH_STATES.ORDER_UPDATED].includes(
        data?.state as SWITCH_STATES
      ) &&
      data?.role === PROVIDER_ROLES.GAINING &&
      !isGenerateSwitchOrder,
    [data?.role, data?.state, isGenerateSwitchOrder]
  );

  const allowMatchFailedActionsForGaining = useMemo(
    () =>
      data?.state === SWITCH_STATES.MATCH_FAILED &&
      data?.role === PROVIDER_ROLES.GAINING &&
      !isGenerateSwitchOrder,
    [data?.role, data?.state, isGenerateSwitchOrder]
  );

  const allowOrderFailedActionsForGaining = useMemo(
    () =>
      [
        SWITCH_STATES.MATCH_CONFIRMED,
        SWITCH_STATES.ORDER_FAILED,
        SWITCH_STATES.UPDATE_FAILED,
        SWITCH_STATES.TRIGGER_FAILED,
        SWITCH_STATES.CANCEL_FAILED,
        SWITCH_STATES.ORDER_CANCELLED
      ].includes(data?.state as SWITCH_STATES) &&
      data?.role === PROVIDER_ROLES.GAINING &&
      !isGenerateSwitchOrder,
    [data?.role, data?.state, isGenerateSwitchOrder]
  );

  const allowActionsForLosing = useMemo(
    () =>
      data?.state === SWITCH_STATES.TRIGGER_REQUESTED &&
      data?.role === PROVIDER_ROLES.LOSING &&
      !isGenerateSwitchOrder,
    [data?.role, data?.state, isGenerateSwitchOrder]
  );

  const allowRetryMatch = useMemo(
    () =>
      [SWITCH_STATES.HUB_DELIVERY_FAILURE, SWITCH_STATES.MATCH_REQUESTED].includes(
        data?.state as SWITCH_STATES
      ) &&
      data?.role === PROVIDER_ROLES.GAINING &&
      !isGenerateSwitchOrder,
    [data?.role, data?.state, isGenerateSwitchOrder]
  );

  const allowErrorActionsForLosing = useMemo(
    () =>
      data?.state === SWITCH_STATES.ORDER_REQUESTED &&
      data?.role === PROVIDER_ROLES.LOSING &&
      (notificationData || []).some(val => val.notificationType === NOTIFICATIONS.OrderRequest) &&
      !isGenerateSwitchOrder,
    [data?.role, data?.state, isGenerateSwitchOrder, notificationData]
  );

  const handleClick = () => {
    // If the user coming from the order list page we are using -1 to navigate to the previous URL this way we can keep the filters
    if (location.state) {
      navigate(-1);
    } else {
      // If the user uses a order's direct url we should redirect the user to the order list page when the user clicks on the back button
      navigate(`/${URLS.ORDERS}`);
    }
  };

  return (
    <div className={classes.header}>
      <div className={classes.leftContent}>
        <div className={classes.leftRow}>
          <Button onClick={handleClick} className={classes.backBtn} data-testid='back-btn'>
            {'< Back to List'}
          </Button>
        </div>
        <div className={classes.leftRow}>
          <Typography variant='h4' className={classes.title}>
            {isGenerateSwitchOrder ? 'Generate Switch Order' : 'Switch Order Details'}
          </Typography>
          {isFetching ? (
            <CircularProgress size={24} />
          ) : (
            !!data && (
              <Stack direction='row' alignItems='center' spacing={1}>
                <OrderProviderType className={classes.type} type={data.role} showLabel={true} />
                <OrderState state={data.state} />
                <Stack direction='row' alignItems='center' spacing={0.5}>
                  <Typography color={'grey.600'} fontWeight='600'>
                    CorrelationId Prefix:
                  </Typography>
                  <Typography>{data.switchId}</Typography>
                  <CopyToClipboard textToCopy={data.switchId} />
                </Stack>
              </Stack>
            )
          )}
        </div>
      </div>
      <div className={classes.rightContent}>
        <Stack direction='row' spacing={1}>
          {data && allowRetryMatch && (
            <Button
              color='warning'
              variant='outlined'
              onClick={() => navigate(`/${URLS.AMEND_MATCH_REQUEST.replace(':id', data.switchId)}`)}
            >
              Retry Match
            </Button>
          )}
          {data && allowMatchFailedActionsForGaining && (
            <Button
              color='warning'
              variant='outlined'
              onClick={() => navigate(`/${URLS.AMEND_MATCH_REQUEST.replace(':id', data.switchId)}`)}
            >
              Amend Match Request
            </Button>
          )}
          {data && allowOrderFailedActionsForGaining && (
            <Button
              color='warning'
              variant='outlined'
              onClick={() => navigate(`/${URLS.AMEND_MATCH_REQUEST.replace(':id', data.switchId)}`)}
            >
              Clone Order
            </Button>
          )}
          {data && allowActionsForGaining && (
            <>
              <CancelOrder order={data} />
              <ChangeSwitchDate order={data} />
              <TriggerSwitch order={data} />
            </>
          )}
          {data && allowErrorActionsForLosing && (
            <>
              <FailOrder
                order={data}
                hasOpenOrderTypeCease={hasOpenOrderTypeCease}
                hasOpenOrderTypeModify={hasOpenOrderTypeModify}
              />
              <ConfirmOrder order={data} />
            </>
          )}
          {data && allowActionsForLosing && <CeaseOfService order={data} />}
        </Stack>
      </div>
    </div>
  );
};
