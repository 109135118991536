import { FunctionComponent } from 'react';
import { AppBar, Box, Stack, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useAuth } from 'app/hooks/useAuth';
import logo from 'assets/pivots-logo-name.svg';

import { RCPMenu } from './RCPMenu';
import { UserMenu } from './UserMenu';

const useStyles = makeStyles()(theme => ({
  appBar: {
    '&.MuiAppBar-colorPrimary': {
      backgroundColor: theme.palette.navbar.main
    }
  },
  link: {
    display: 'flex'
  }
}));

export const Navigation: FunctionComponent = () => {
  const { classes } = useStyles();
  const auth = useAuth();

  return (
    <AppBar className={classes.appBar} position='static' color='primary' data-testid='navigation'>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Link className={classes.link} to='/'>
            <img src={logo} width='165px' height='32px' />
          </Link>
        </Box>

        {!auth.isLoading && auth.isAuthenticated && (
          <Stack direction='row' spacing={2}>
            <RCPMenu />
            <UserMenu />
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
};
