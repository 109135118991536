import { DATE_TIME_FULL_FORMAT } from 'app/constants';
import { ImplicationsData } from 'app/types/notifications';
import { Address } from 'app/types/switchOrder';

import { formatDateTime } from './date-utils';

export const formatAddress = (address: Address, fullAddress = false) => {
  return `${address.singleLineAddress ? `${address.singleLineAddress}, ` : ''}${address.postTown}${
    fullAddress ? `, ${address.postcode}${address.uprn ? `, ${address.uprn}` : ''}` : ''
  }`;
};

export const formatServiceImplications = (serviceImplications: ImplicationsData[]) => {
  if (serviceImplications.length === 0) return undefined;
  return `Switching Implications ${serviceImplications
    .map(implication => {
      const byPart = implication.sentMethod ? ` by ${implication.sentMethod}` : '';
      const toPart = implication.sentTo ? ` to ${implication.sentTo}` : '';
      const atPart = implication.sentBy
        ? ` at ${formatDateTime(implication.sentBy, DATE_TIME_FULL_FORMAT)}`
        : '';

      return `sent${byPart}${toPart}${atPart}`;
    })
    .join(' and ')}`;
};

export const formatCurrency = (val: string) =>
  parseFloat(val)
    ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(parseFloat(val))
    : '0';

export const formatNumber = (val: number) => new Intl.NumberFormat().format(val);
