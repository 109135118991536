export enum SWITCH_STATES {
  MATCH_REQUESTED = 'MATCH_REQUESTED',
  MATCH_CONFIRMED = 'MATCH_CONFIRMED',
  MATCH_FAILED = 'MATCH_FAILED',
  MATCH_EXPIRED = 'MATCH_EXPIRED',

  ORDER_REQUESTED = 'ORDER_REQUESTED',
  ORDER_CONFIRMED = 'ORDER_CONFIRMED',
  ORDER_FAILED = 'ORDER_FAILED',
  ORDER_EXPIRED = 'ORDER_EXPIRED',
  ORDER_UPDATED = 'ORDER_UPDATED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_COMPLETED = 'ORDER_COMPLETED',

  UPDATE_REQUESTED = 'UPDATE_REQUESTED',
  UPDATE_FAILED = 'UPDATE_FAILED',

  TRIGGER_REQUESTED = 'TRIGGER_REQUESTED',
  TRIGGER_FAILED = 'TRIGGER_FAILED',

  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  CANCEL_FAILED = 'CANCEL_FAILED',

  HUB_DELIVERY_FAILURE = 'HUB_DELIVERY_FAILURE'
}

export enum PROCESSES {
  GPLB = 'GPLB',
  OTS = 'OTS'
}

export enum PROVIDER_ROLES {
  GAINING = 'GAINING',
  LOSING = 'LOSING'
}

export enum NOTIFY_METHODS {
  EMAIL = 'email',
  SMS = 'sms',
  POST = '1st class post'
}

export enum SERVICE_TYPES {
  VOICE = 'NBICS',
  BROADBAND = 'IAS'
}

export enum SWITCH_ACTIONS {
  SERVICE_FOUND = 'ServiceFound',
  SERVICE_WITH_ANOTHER_RCP = 'ServiceWithAnotherRCP',
  SERVICE_WITH_ANOTHER_CUSTOMER = 'ServiceWithAnotherCust',
  SERVICE_NOT_FOUND = 'ServiceNotFound',
  FORCED_CEASE = 'ForcedCease',
  OPTION_TO_CEASE = 'OptionToCease',
  OPTION_TO_RETAIN = 'OptionToRetain',

  REQUEST_TO_CEASE = 'RequestToCease',
  INTENTION_TO_PORT = 'Intention to Port',
  UNKNOWN = 'Unknown'
}

export enum SWITCH_ACTION_OPTIONS {
  CEASE = 'cease',
  PORT = 'port',
  IDENTIFY = 'identify'
}
