import { FunctionComponent, useEffect } from 'react';
import { Container } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ORIGINAL_DESTINATION } from 'app/constants/app';
import { useAuth } from 'app/hooks/useAuth';
import Routes from 'app/modules/Routes';

import { AuthErrorPage } from './Errors/AuthErrorPage';
import { UnauthorisedPage } from './Errors/UnauthorisedPage';
import { Footer } from './Footer';
import { Navigation } from './Navigation';
import { PageLoadingIndicator } from './PageLoadingIndicator';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  main: {
    display: 'flex',
    flex: '1 1 auto',
    padding: theme.spacing(2, 0, 2.5),

    '&>*': {
      width: '100%'
    }
  }
}));

export const AppLayout: FunctionComponent = () => {
  const { classes } = useStyles();
  const auth = useAuth();

  // automatically sign-in
  useEffect(() => {
    if (!auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !auth.error) {
      window.sessionStorage.setItem(ORIGINAL_DESTINATION, window.location.pathname);
      auth.signinRedirect();
    }
  }, [auth]);

  return (
    <div className={classes.container}>
      <Navigation />
      <Container maxWidth={false} className={classes.main}>
        {auth.isLoading && <PageLoadingIndicator />}

        {auth.error && <AuthErrorPage />}

        {!auth.isLoading && !auth.error ? (
          auth.isAuthenticated ? (
            <Routes />
          ) : (
            <UnauthorisedPage />
          )
        ) : (
          <div />
        )}
      </Container>
      <Footer />
    </div>
  );
};
