import React, { useEffect } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from 'tss-react/mui';

import { useIdentity } from 'app/hooks/useIdentity';
import { useRcps } from 'app/hooks/useRcps';
import { getProviderLabel } from 'app/utils/provider-utils';

const useStyles = makeStyles()(() => ({
  button: {
    textTransform: 'none',
    border: 'none'
  }
}));

export const RCPMenu = () => {
  const { classes } = useStyles();
  const { rcpId, rcpOptions, setSelectedRcp } = useIdentity();
  const { allProviders } = useRcps();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    setSelectedRcp(rcpOptions[index]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRcpLabel = (rcpVal: string) => {
    const found = allProviders.find(val => val.rcpId === rcpVal);
    return found ? getProviderLabel({ rcpId: found.rcpId, rcpName: found.name }) : rcpVal;
  };

  useEffect(() => {
    setSelectedIndex(rcpOptions.findIndex(val => val === rcpId) ?? 0);
  }, [rcpOptions, rcpId]);

  return rcpOptions.length > 1 ? (
    <>
      <Button
        id='rcp-menu'
        data-testid='rcp-menu'
        className={classes.button}
        aria-controls={anchorEl ? 'rcp-menu-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={anchorEl ? 'true' : undefined}
        variant='outlined'
        color='inherit'
        disableElevation
        size='medium'
        onClick={handleClickListItem}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {getRcpLabel(rcpOptions[selectedIndex])}
      </Button>
      <Menu
        id='rcp-menu-menu'
        data-testid='rcp-menu-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'rcp-menu-button',
          role: 'listbox'
        }}
      >
        {rcpOptions.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={event => handleMenuItemClick(event, index)}
          >
            {getRcpLabel(option)}
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : null;
};
