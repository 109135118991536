import { FunctionComponent } from 'react';
import Grid from '@mui/material/Grid';

import { LabelValue, Panel } from 'app/components';
import { PROVIDER_ROLES, SWITCH_STATES } from 'app/constants';
import { SwitchOrderFull } from 'app/types/switchOrder';

export const CustomerDetailsPanel: FunctionComponent<{
  order: SwitchOrderFull;
}> = ({ order }) => (
  <Panel
    title={
      order.state === SWITCH_STATES.MATCH_FAILED
        ? 'Match Request Customer Details'
        : 'Customer Details'
    }
    data-testid='customer-details'
  >
    <Grid container spacing={2}>
      <Grid item xs>
        <LabelValue label='Surname'>{order.customerSurname}</LabelValue>
        {order.role === PROVIDER_ROLES.GAINING ? (
          <LabelValue label='UI Reference'>{order.uiReference}</LabelValue>
        ) : (
          <LabelValue label='Account Ref'>{order.accountRef}</LabelValue>
        )}
      </Grid>
      <Grid item xs>
        <LabelValue label='Address'>
          {order.customerAddress && order.customerAddress.postcode ? (
            <>
              {(order.customerAddress.singleLineAddress?.split(',') || []).map((line, index) => (
                <span key={index}>
                  {line},<br />
                </span>
              ))}
              {order.customerAddress.postTown},<br />
              {order.customerAddress.postcode}
              {order.customerAddress.uprn && (
                <>
                  <br />
                  UPRN: {order.customerAddress.uprn}
                </>
              )}
            </>
          ) : undefined}
        </LabelValue>
      </Grid>
    </Grid>
  </Panel>
);
