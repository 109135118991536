import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { DATA_IMPORTS_API_ROOT } from 'app/constants';
import {
  DataImportOperationSummaryItem,
  ErrorResponse,
  FilterValue,
  ImportDataResponse,
  PaginatedResponse
} from 'app/types';
import { convertFilterValuesForAPI } from 'app/utils/filters-utils';

import { useAxiosWrapper } from './useAxiosWrapper';

export interface FetchDataImportOptions {
  page?: number;
  size?: number;
  sort?: string;
  filters?: FilterValue[];
}

export const useFetchDataImports = (
  { filters, ...options }: FetchDataImportOptions,
  enabled = true
) => {
  const convertedFilters = convertFilterValuesForAPI(filters || []);

  const queryOptions = {
    ...options,
    ...convertedFilters
  };

  const axios = useAxiosWrapper();

  return useQuery({
    queryKey: ['imports', queryOptions],
    queryFn: () =>
      axios
        .get<PaginatedResponse<DataImportOperationSummaryItem>>(DATA_IMPORTS_API_ROOT, {
          params: queryOptions
        })
        .then(response => response.data),
    enabled
  });
};

interface ImportCustomerDataMutationOptions {
  file: string;
}

export const useImportCustomerDataMutation = () => {
  const axios = useAxiosWrapper();

  return useMutation<ImportDataResponse, unknown, ImportCustomerDataMutationOptions>({
    mutationKey: 'importCustomerDataMutation',
    mutationFn: (requestBody: ImportCustomerDataMutationOptions) =>
      axios
        .post<ImportDataResponse>(DATA_IMPORTS_API_ROOT, requestBody)
        .then(response => response.data)
        .catch((error: ErrorResponse | AxiosError) => {
          throw error;
        })
  });
};
