import { FunctionComponent } from 'react';
import { IconButton, Typography } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import { PROVIDER_ROLES } from 'app/constants/switchProcess';
import { PROVIDER_ROLES_TYPE } from 'app/types/typesFromEnums';
import { getProviderTypeLabel } from 'app/utils/order-utils';
import { ReactComponent as GainingIcon } from 'assets/icon-gaining.svg';
import { ReactComponent as LosingIcon } from 'assets/icon-losing.svg';

export interface OrderProviderTypeProps {
  type: PROVIDER_ROLES_TYPE;
  showLabel?: boolean;
  className?: string | undefined;
}

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export const OrderProviderType: FunctionComponent<OrderProviderTypeProps> = ({
  type,
  showLabel = false,
  className
}) => {
  const { classes } = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <IconButton disabled data-testid='orderProviderType'>
        {type === PROVIDER_ROLES.GAINING ? (
          <SvgIcon color='success' component={GainingIcon} />
        ) : (
          <SvgIcon color='error' component={LosingIcon} />
        )}
      </IconButton>
      {showLabel && <Typography variant='h5'>{getProviderTypeLabel(type)}</Typography>}
    </div>
  );
};
