import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { PROCESSES } from 'app/constants/switchProcess';
import { useAuth } from 'app/hooks/useAuth';
import { RcpFullData } from 'app/types/rcp';

import { useIdentity } from './useIdentity';
import { useFetchRcps } from './useRcpQueries';

type RcpContextType = {
  isLoading: boolean;
  providers: RcpFullData[];
  allProviders: RcpFullData[];
  activeOTSProviders: RcpFullData[];
  userProvider?: RcpFullData;
};

const RcpContext = createContext<RcpContextType>({
  isLoading: true,
  providers: [],
  allProviders: [],
  activeOTSProviders: []
});

export const RcpProvider = (props: PropsWithChildren) => {
  const { rcpId } = useIdentity();
  const auth = useAuth();

  const { isLoading, data } = useFetchRcps(auth.isAuthenticated);

  const userProvider = useMemo(
    () => (data?.content || []).find(val => val.rcpId === rcpId),
    [data?.content, rcpId]
  );

  const providers = useMemo(
    () => (data?.content || []).filter(val => val.rcpId !== rcpId),
    [data?.content, rcpId]
  );
  const activeOTSProviders = useMemo(
    () =>
      providers.filter(
        val =>
          val.processSupport?.find(
            inner =>
              inner.process === PROCESSES.OTS &&
              inner.status.toLowerCase() === 'ACTIVE'.toLowerCase()
          )
      ),
    [providers]
  );

  return (
    <RcpContext.Provider
      value={{
        isLoading,
        providers,
        allProviders: data?.content || [],
        activeOTSProviders,
        userProvider
      }}
    >
      {props.children}
    </RcpContext.Provider>
  );
};

export const useRcps = () => useContext(RcpContext);
