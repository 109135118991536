import { PROVIDER_ROLES, SWITCH_STATES } from 'app/constants/switchProcess';
import { MatchRequestValues } from 'app/types/matchRequest';
import { OpenOrder } from 'app/types/notifications';
import { PROVIDER_ROLES_TYPE, SWITCH_STATES_TYPE } from 'app/types/typesFromEnums';

export const getStateValueLabel = (state: SWITCH_STATES_TYPE) => {
  switch (state) {
    case SWITCH_STATES.CANCEL_FAILED:
      return 'Cancel Failed';
    case SWITCH_STATES.CANCEL_REQUESTED:
      return 'Cancel Requested';
    case SWITCH_STATES.MATCH_CONFIRMED:
      return 'Match Confirmed';
    case SWITCH_STATES.MATCH_FAILED:
      return 'Match Failed';
    case SWITCH_STATES.MATCH_REQUESTED:
      return 'Match Requested';
    case SWITCH_STATES.MATCH_EXPIRED:
      return 'Match Expired';
    case SWITCH_STATES.ORDER_CANCELLED:
      return 'Order Cancelled';
    case SWITCH_STATES.ORDER_COMPLETED:
      return 'Order Completed';
    case SWITCH_STATES.ORDER_CONFIRMED:
      return 'Order Confirmed';
    case SWITCH_STATES.ORDER_EXPIRED:
      return 'Order Expired';
    case SWITCH_STATES.ORDER_FAILED:
      return 'Order Failed';
    case SWITCH_STATES.ORDER_REQUESTED:
      return 'Order Requested';
    case SWITCH_STATES.ORDER_UPDATED:
      return 'Order Updated';
    case SWITCH_STATES.TRIGGER_FAILED:
      return 'Trigger Failed';
    case SWITCH_STATES.TRIGGER_REQUESTED:
      return 'Trigger Requested';
    case SWITCH_STATES.UPDATE_FAILED:
      return 'Update Failed';
    case SWITCH_STATES.UPDATE_REQUESTED:
      return 'Update Requested';
    case SWITCH_STATES.HUB_DELIVERY_FAILURE:
      return 'Hub Delivery Failure';
    default:
      return state;
  }
};

export const getProviderTypeLabel = (type: PROVIDER_ROLES_TYPE) =>
  type === PROVIDER_ROLES.GAINING ? 'Gaining' : 'Losing';

export const sanitiseNewMatchRequest = (matchReq: MatchRequestValues) =>
  ({
    lrcpId: matchReq.lrcpId,
    grcpId: matchReq.grcpId,
    grcpBrandName: matchReq.grcpBrandName,
    name: matchReq.name.trim(),
    accountRef: matchReq.accountRef ? matchReq.accountRef.trim() : undefined,
    address: {
      uprn: matchReq.address.uprn ? matchReq.address.uprn.trim() : undefined,
      singleLineAddress: matchReq.address.singleLineAddress.trim(),
      postTown: matchReq.address.postTown.trim(),
      postcode: matchReq.address.postcode.trim()
    },
    services: matchReq.services.map(val => ({
      serviceType: val.serviceType,
      serviceIdentifier: val.serviceIdentifier ? val.serviceIdentifier.trim() : undefined,
      action: val.action
    })),
    uiReference: matchReq.uiReference
  }) as MatchRequestValues;

export const isOpenOrder = (openOrder: OpenOrder) =>
  openOrder.orderType &&
  openOrder.orderType !== '' &&
  `${openOrder.orderType}`.toLocaleLowerCase() !== 'NONE'.toLocaleLowerCase();
