import { FunctionComponent } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '48px'
  }
}));

export const NotFoundPage: FunctionComponent = () => {
  const { classes } = useStyles();

  return (
    <Box title='Page Not Found' data-testid='not-found' className={classes.container}>
      <Typography variant='h1'>Not Found</Typography>
      <Typography variant='body1'>The page you requested could not be found.</Typography>
    </Box>
  );
};
