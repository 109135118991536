import { DATA_IMPORT_STATES } from 'app/constants';
import { DATA_IMPORT_STATES_TYPE } from 'app/types/typesFromEnums';

export const getDataImportStateValueLabel = (state: DATA_IMPORT_STATES_TYPE) => {
  switch (state) {
    case DATA_IMPORT_STATES.SCHEDULED:
      return 'Scheduled';
    case DATA_IMPORT_STATES.COMPLETED:
      return 'Completed';
    case DATA_IMPORT_STATES.FAILED:
      return 'Failed';
    case DATA_IMPORT_STATES.STARTED:
      return 'Started';
    default:
      return state;
  }
};
