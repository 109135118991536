import * as yup from 'yup';

import { SERVICE_TYPES, SWITCH_ACTION_OPTIONS } from 'app/constants/switchProcess';

export const validationSchema = yup.object({
  name: yup.string().trim().required('Required'),
  address: yup.object({
    singleLineAddress: yup.string().trim().required('Required'),
    postTown: yup.string().trim().required('Required'),
    postcode: yup
      .string()
      .trim()
      .required('Required')
      .matches(/^[A-Z]{1,2}[0-9][A-Z0-9]?\s?[0-9][A-Z]{2}$/, 'Incorrect postcode format')
  }),
  lrcpId: yup.string().required('Required'),
  accountRef: yup.string().trim(),
  services: yup
    .array()
    .of(
      yup.object().shape({
        serviceType: yup.string().required('Required'),
        serviceIdentifier: yup
          .string()
          .trim()
          .when(['serviceType', 'action'], {
            is: (serviceType: string, action: string) =>
              serviceType === SERVICE_TYPES.VOICE && action !== SWITCH_ACTION_OPTIONS.CEASE,
            then: schema => schema.required('Required')
          }),
        action: yup.string().required('Required')
      })
    )
    .min(1)
    .required()
});
