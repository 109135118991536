import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { DataGrid, GridPaginationModel, GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { ErrorBanner } from 'app/components';
import {
  ORDER_TABLE_FILTER_SEARCH_PARAM,
  ORDER_TABLE_PAGE_SEARCH_PARAM,
  ORDER_TABLE_PAGE_SIZE,
  ORDER_TABLE_PAGESIZE_SEARCH_PARAM,
  ORDER_TABLE_ROW_HEIGHT,
  ORDER_TABLE_SORT_SEARCH_PARAM,
  ORDER_TABLE_SORTDIR_SEARCH_PARAM,
  ORDERS_TABLE_MIN_HEIGHT
} from 'app/constants';
import { useFetchDataImports } from 'app/hooks/useDataImportQueries';
import { FilterValue } from 'app/types';
import { formatError } from 'app/utils/error-utils';
import {
  buildSearchParamsFromFilterValues,
  convertStringToFilterValue
} from 'app/utils/filters-utils';

import { generateDataImportTableColumns } from './dataImportTableColumns';
import { FilterControls } from './FilterControls';
import { ImportCustomerData } from './Modals';

const useStyles = makeStyles()(theme => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerRight: {
    justifyContent: 'center'
  },
  filtersContainer: {
    padding: theme.spacing(3.5, 0)
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  gridContainer: {
    flex: '1 1 auto',

    '& .MuiDataGrid-root': {
      borderWidth: 0,

      '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
        outline: 0
      }
    }
  }
}));

export const DataImport: FunctionComponent = () => {
  const { classes } = useStyles();

  const [filters, setFilters] = useState<FilterValue[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: ORDER_TABLE_PAGE_SIZE
  });

  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'createdAt', sort: 'desc' }]);

  const queryOptions = useMemo(
    () => ({
      page: paginationModel.page,
      size: paginationModel.pageSize,
      sort: `${sortModel[0].field},${sortModel[0].sort},ignorecase`,
      filters: filters
    }),
    [filters, paginationModel.page, paginationModel.pageSize, sortModel]
  );

  const { isFetching, error: errorDataImports, data } = useFetchDataImports(queryOptions, true);

  useEffect(
    () =>
      setRowCount(prevRowCount =>
        data?.totalElements !== undefined ? data?.totalElements : prevRowCount
      ),
    [data, data?.totalElements, setRowCount]
  );

  const handlePagination = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel);
  };

  const handleSorting = (model: GridSortModel) => {
    setSortModel(model);
  };

  const handleFilterChange = (filters: FilterValue[]) => {
    setSearchParams(buildSearchParamsFromFilterValues(filters, searchParams));
    setFilters(filters);
  };

  const tableColumns = useMemo(() => {
    return generateDataImportTableColumns();
  }, []);

  useEffect(() => {
    setFilters(
      searchParams
        .getAll(ORDER_TABLE_FILTER_SEARCH_PARAM)
        .map(val => convertStringToFilterValue(val))
        .filter((item): item is FilterValue => !!item)
    );

    setPaginationModel({
      page: Number(searchParams.get(ORDER_TABLE_PAGE_SEARCH_PARAM)) ?? 0,
      pageSize: searchParams.get(ORDER_TABLE_PAGESIZE_SEARCH_PARAM)
        ? Number(searchParams.get(ORDER_TABLE_PAGESIZE_SEARCH_PARAM))
        : ORDER_TABLE_PAGE_SIZE
    });

    setSortModel([
      {
        field: searchParams.get(ORDER_TABLE_SORT_SEARCH_PARAM) ?? 'createdAt',
        sort: (searchParams.get(ORDER_TABLE_SORTDIR_SEARCH_PARAM) as GridSortDirection) ?? 'desc'
      }
    ]);
  }, [searchParams, setSearchParams]);

  return (
    <div className={classes.pageContainer}>
      <div className={classes.header}>
        <Typography variant='h3'>Data Import Management</Typography>

        <Stack className={classes.headerRight}>
          <ImportCustomerData />
        </Stack>
      </div>

      <div className={classes.filtersContainer}>
        <div className={classes.filters}>
          <FilterControls filters={filters} onFilterChange={handleFilterChange} />
        </div>
        {!!errorDataImports && (
          <Box mt={2}>
            <ErrorBanner error={formatError(errorDataImports)} />
          </Box>
        )}
      </div>

      <Paper
        data-testid='imports-table'
        className={classes.gridContainer}
        sx={{ height: ORDERS_TABLE_MIN_HEIGHT }}
      >
        <DataGrid
          rows={data?.content || []}
          columns={tableColumns}
          columnBuffer={9}
          disableColumnMenu
          getRowId={row => row.importId}
          hideFooterSelectedRowCount
          initialState={{
            pagination: {
              paginationModel
            }
          }}
          loading={isFetching}
          onPaginationModelChange={newModel => handlePagination(newModel)}
          onSortModelChange={model => handleSorting(model)}
          paginationModel={paginationModel}
          rowCount={rowCount}
          rowHeight={ORDER_TABLE_ROW_HEIGHT}
          paginationMode='server'
          pageSizeOptions={[10, 25, 50, 100]}
          sortingMode='server'
          sortingOrder={['desc', 'asc']}
          sortModel={sortModel}
        />
      </Paper>
    </div>
  );
};
