import { Fragment, FunctionComponent, useMemo } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import { Panel } from 'app/components';
import { ServiceActionOption } from 'app/components/Orders';
import { SERVICE_TYPES } from 'app/constants';
import { ServiceItemType } from 'app/types/serviceDetails';
import { SWITCH_ACTION_OPTIONS_TYPE } from 'app/types/typesFromEnums';
import { getServiceTypeLabel } from 'app/utils/service-utils';

const useStyles = makeStyles()(theme => ({
  leftColumn: {
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.divider
  },

  divider: {
    width: `calc(100% - ${theme.spacing(2)})`,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),

    '&:last-of-type': {
      display: 'none'
    }
  }
}));

const ServiceRow: FunctionComponent<{ service?: ServiceItemType; rightColumn?: boolean }> = ({
  service,
  rightColumn
}) => {
  const { classes } = useStyles();

  if (!service)
    return <Grid item xs={6} columnSpacing={2} rowSpacing={1} alignItems='center'></Grid>;

  return (
    <Grid
      container
      item
      xs={6}
      columnSpacing={2}
      rowSpacing={1}
      className={clsx(rightColumn ? '' : classes.leftColumn)}
    >
      <Grid container item xs={12} alignItems='center'>
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant='h6'>{getServiceTypeLabel(service.serviceType)}</Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={4}>
          <ServiceActionOption action={service.action as SWITCH_ACTION_OPTIONS_TYPE} />
        </Grid>
        <Grid item xs={12} md={3} lg={4}>
          <Box width={160}>{service.serviceIdentifier}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const SwitchMatchRequestServicesPanel: FunctionComponent<{
  services: ServiceItemType[];
}> = ({ services }) => {
  const { classes } = useStyles();

  const [broadband, voice] = useMemo(
    () => [
      services.filter(({ serviceType }) => serviceType === SERVICE_TYPES.BROADBAND),
      services.filter(({ serviceType }) => serviceType === SERVICE_TYPES.VOICE)
    ],
    [services]
  );

  const numRows = useMemo(
    () => (broadband.length > voice.length ? broadband.length : voice.length),
    [broadband.length, voice.length]
  );

  return (
    <Panel title='Switch Match Request Services'>
      <Grid container columnSpacing={2} rowSpacing={0}>
        {[...Array(numRows)].map((_, index) => (
          <Fragment key={`service-${index}`}>
            <ServiceRow service={broadband[index]} />
            <ServiceRow service={voice[index]} rightColumn />
            <Divider variant='fullWidth' className={classes.divider} />
          </Fragment>
        ))}
      </Grid>
    </Panel>
  );
};
