import { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

import { PROVIDER_ROLES, SWITCH_ACTIONS } from 'app/constants/switchProcess';
import { ServiceAction } from 'app/types/serviceDetails';
import ceaseIcon from 'assets/service-icons/cease.svg';
import identifyIcon from 'assets/service-icons/identify.svg';
import notFoundIcon from 'assets/service-icons/not-found.svg';
import portGainingIcon from 'assets/service-icons/port-gaining.svg';
import portLosingIcon from 'assets/service-icons/port-losing.svg';
import retainIcon from 'assets/service-icons/retain.svg';

export interface SwitchActionProps {
  action: ServiceAction;
  className?: string | undefined;
}

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(0.5)
  }
}));

const getSwitchActionIcon = (action: ServiceAction) => {
  switch (action.original) {
    case SWITCH_ACTIONS.SERVICE_FOUND:
      return action.intent === 'port'
        ? action.role === PROVIDER_ROLES.GAINING
          ? portGainingIcon
          : portLosingIcon
        : action.intent === 'identify'
        ? identifyIcon
        : ceaseIcon;
    case SWITCH_ACTIONS.FORCED_CEASE:
    case SWITCH_ACTIONS.OPTION_TO_CEASE:
    case SWITCH_ACTIONS.REQUEST_TO_CEASE:
      return ceaseIcon;
    case SWITCH_ACTIONS.OPTION_TO_RETAIN:
      return action.role === PROVIDER_ROLES.GAINING ? portGainingIcon : retainIcon;
    case SWITCH_ACTIONS.INTENTION_TO_PORT:
      return retainIcon;
    case SWITCH_ACTIONS.SERVICE_WITH_ANOTHER_CUSTOMER:
    case SWITCH_ACTIONS.SERVICE_NOT_FOUND:
    case SWITCH_ACTIONS.SERVICE_WITH_ANOTHER_RCP:
    case SWITCH_ACTIONS.UNKNOWN:
    default:
      return notFoundIcon;
  }
};

const getSwitchActionLabel = (action: ServiceAction) => {
  switch (action.original) {
    case SWITCH_ACTIONS.SERVICE_FOUND:
      return action.intent === 'port'
        ? 'Port'
        : action.intent === 'identify'
        ? 'Identify'
        : 'Cease';
    case SWITCH_ACTIONS.FORCED_CEASE:
      return 'Forced Cease';
    case SWITCH_ACTIONS.OPTION_TO_RETAIN:
      return action.role === PROVIDER_ROLES.GAINING ? 'Retain with Existing Provider' : 'Retain';
    case SWITCH_ACTIONS.OPTION_TO_CEASE:
      return 'Cease';
    case SWITCH_ACTIONS.SERVICE_NOT_FOUND:
      return 'Service Not Found';
    case SWITCH_ACTIONS.SERVICE_WITH_ANOTHER_RCP:
      return 'Service with Another Provider';

    case SWITCH_ACTIONS.SERVICE_WITH_ANOTHER_CUSTOMER:
      return 'Service with Another Customer';
    case SWITCH_ACTIONS.REQUEST_TO_CEASE:
      return 'Request to Cease';
    case SWITCH_ACTIONS.INTENTION_TO_PORT:
      return 'Intention to Port';
    case SWITCH_ACTIONS.UNKNOWN:
    default:
      return SWITCH_ACTIONS.UNKNOWN;
  }
};

export const SwitchAction: FunctionComponent<SwitchActionProps> = ({ action, className }) => {
  const { classes } = useStyles();

  return (
    <div className={clsx(classes.container, className)} data-testid='switchAction'>
      <img src={getSwitchActionIcon(action)} width='20px' height='20px' className={classes.icon} />
      <Typography variant='body1'>{getSwitchActionLabel(action)}</Typography>
    </div>
  );
};
