import { FunctionComponent, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';

import { useAuth } from 'app/hooks/useAuth';
import theme from 'app/theme';

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(3)
  }
}));

export const AuthErrorPage: FunctionComponent = () => {
  const { classes } = useStyles();
  const auth = useAuth();

  useEffect(() => {
    auth.signinRedirect();
  }, [auth]);

  return (
    <Box title='Authentication Error' data-testid='auth-error' className={classes.container}>
      <Typography variant='h3' gutterBottom>
        Authentication Error
      </Typography>
      <Typography variant='body1' gutterBottom>
        Redirecting to login...
      </Typography>
      <Typography variant='body1' gutterBottom>
        If you are not redirected soon try using the bottom below.
      </Typography>
      <Box my={theme.spacing(3)}>
        <Button variant='contained' onClick={() => auth.signinRedirect()}>
          Return To Login
        </Button>
      </Box>
      {auth.error && (
        <>
          <Typography variant='h6'>Authentication Error:</Typography>
          <Typography variant='body1'>
            <code>{auth.error.message}</code>
          </Typography>
        </>
      )}
    </Box>
  );
};
