import { FunctionComponent } from 'react';
import Chip from '@mui/material/Chip';
import { makeStyles } from 'tss-react/mui';

import { SWITCH_STATES } from 'app/constants';
import { SWITCH_STATES_TYPE } from 'app/types/typesFromEnums';
import { getStateValueLabel } from 'app/utils/order-utils';

const useStyles = makeStyles()(() => ({
  state: {
    fontSize: '16px',
    lineHeight: 1.5
  }
}));

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    MATCH_REQUESTED: true;
    MATCH_CONFIRMED: true;
    MATCH_FAILED: true;
    MATCH_EXPIRED: true;
    ORDER_REQUESTED: true;
    ORDER_FAILED: true;
    ORDER_CONFIRMED: true;
    ORDER_EXPIRED: true;
    UPDATE_REQUESTED: true;
    UPDATE_FAILED: true;
    ORDER_UPDATED: true;
    TRIGGER_REQUESTED: true;
    ORDER_COMPLETED: true;
    TRIGGER_FAILED: true;
    CANCEL_REQUESTED: true;
    ORDER_CANCELLED: true;
    CANCEL_FAILED: true;
    HUB_DELIVERY_FAILURE: true;
  }
}

export const OrderState: FunctionComponent<{ state: SWITCH_STATES_TYPE }> = ({ state }) => {
  const { classes } = useStyles();

  return (
    <Chip
      className={classes.state}
      color={state in SWITCH_STATES ? state : 'default'}
      label={getStateValueLabel(state)}
      data-testid='orderState'
    />
  );
};
